import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';

import './local-dev-configs';
import './i18n';
import './index.css';

import React, { Suspense } from 'react';

import { createInstance, MatomoProvider } from '@jonkoops/matomo-tracker-react';
import { LicenseInfo } from '@mui/x-data-grid-pro';
import browserUpdate from 'browser-update';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';

// eslint-disable-next-line import/no-cycle
import { configureRequestHeaders } from './apis/nRail/userManagement';
// eslint-disable-next-line import/no-cycle
// import Parts from './Apps/parts';
// eslint-disable-next-line import/no-cycle
import store from './app/store';
import LoadingSpinner from './components/LoadingSpinner';
import * as serviceWorker from './serviceWorker';
import LoggedInUser from './views/LoggedInUser';

// import Frame from './Apps/frame/Frame';

// eslint-disable-next-line global-require,@typescript-eslint/no-var-requires
global.Buffer = global.Buffer || require('buffer').Buffer;

console.devTableLog =
  process.env.REACT_APP_LOCAL_DEV_EVN || localStorage.getItem('debugNrl')
    ? console.table
    : () => { };

if (process.env.REACT_APP_FVM_MUI_DATA_GRID) {
  LicenseInfo.setLicenseKey(process.env.REACT_APP_FVM_MUI_DATA_GRID);
}

browserUpdate({
  required: { f: 25, o: 17, s: 9, c: 10 },
  // onShow: () => console.devLog('something'),
  // test: true,
  insecure: true,
  reminder: 24,
  reminderClosed: 150,
  l:
    localStorage.getItem('myLocale')?.slice(0, 2) ||
    localStorage.getItem('i18nextLng')?.slice(0, 2) ||
    undefined,
  text_in_en: {
    msg: 'This Browser is not supported for this application (({brow_name})).',
    msgmore: 'Use another browser such as Firefox, Chrome, Opera or Safari',
    bignore: 'Ignore',
  },
  text_in_de: {
    msg: 'Ihr Browser wird für diese Applikation nicht unterstützt (({brow_name})).',
    msgmore: 'Probieren Sie es mit einem anderen Browser wie Firefox, Chrome, Opera or Safari',
    bignore: 'Ignorieren',
  },
  newwindow: true,
  url: undefined,
  noclose: true,
  nomessage: false,
  jsshowurl: '//browser-update.org/update.show.min.js',
  container: document.body,
  no_permanent_hide: false,
});

// eslint-disable-next-line @typescript-eslint/no-unused-expressions
window.localStorage.getItem('userToken') && configureRequestHeaders().then(() => { });

const instance = createInstance({
  urlBase: 'https://nrail.matomo.cloud/',
  siteId: 3,
  userId: 'UID76903202', // optional, default value: `undefined`.
  // trackerUrl: 'https://nrail.matomo.cloud/tracking.php', // optional, default value: `${urlBase}matomo.php`
  // srcUrl: 'https://nrail.matomo.cloud/tracking.js', // optional, default value: `${urlBase}matomo.js`
  disabled: false, // optional, false by default. Makes all tracking calls no-ops if set to true.
  heartBeat: {
    // optional, enabled by default
    active: true, // optional, default value: true
    seconds: 10, // optional, default value: `15
  },
  linkTracking: false, // optional, default value: true
  configurations: {
    // optional, default value: {}
    // any valid matomo configuration, all below are optional
    disableCookies: true,
    setSecureCookie: true,
    setRequestMethod: 'POST',
  },
});

// eslint-disable-next-line react/no-render-return-value
// eslint-disable-next-line import/no-mutable-exports
let renderer: unknown = <div>failed</div>;
if (document.getElementById('root')) {
  // eslint-disable-next-line react/no-render-return-value
  renderer = ReactDOM.render(
    <React.StrictMode>
      <Provider store={store}>
        <Suspense fallback={<LoadingSpinner />}>
          <MatomoProvider value={instance}>
            <LoggedInUser application={process.env.REACT_APP_NRL_PROJECT as string} />
          </MatomoProvider>
        </Suspense>
      </Provider>
    </React.StrictMode>,
    document.getElementById('root')
  );
}

// const renderer = ReactDOM.render(
//   <React.StrictMode>
//     <Provider store={store}>
//       <Suspense fallback={<LoadingSpinner />}>
//         <LoggedInUser application={process.env.REACT_APP_NRL_PROJECT} />
//       </Suspense>
//     </Provider>
//   </React.StrictMode>,
//   document.getElementById('root')
// );

export { store };
export default renderer;
// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
