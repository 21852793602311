import { createSlice } from '@reduxjs/toolkit';

const basePath = window?.REACT_APP_NRL_BASE_URL?.replace(/\/$/, '');
let basePathParts = window?.REACT_APP_NRL_PARTS_URL?.replace(/\/$/, '');
let basePathUm = window?.REACT_APP_NRL_UM_URL?.replace(/\/$/, '');
let basePathLogBook = window?.REACT_APP_NRL_LOGBOOK_URL?.replace(/\/$/, '');

let domain = '';
let domainsFoundArray = basePath?.match(/((development\.)|(staging\.)|(integration\.))?[a-zA-Z\d]+\.nrail\.de/g) || [];
domain = domainsFoundArray[0];

if (window.REACT_APP_NRL_ENV === 'production' || basePath.match(/api\.nrail\.de/)) {
  domain = 'nrail.de';
}
const initialState = {
  domain,
  basePath,
  um: {
    basePath: basePathUm,
    baseUrl: undefined,
    version: undefined,
  },
  parts: {
    basePath: basePathParts,
    baseUrl: undefined,
    version: undefined,
  },
  logbook: {
    basePath: basePathLogBook,
    baseUrl: undefined,
    version: undefined,
  },
};

const apiSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setBasePath: (state, action) => {
      state.basePath = action.payload;
      state.um = {
        basePath: action.payload,
        ...state.um,
      };
      state.parts = {
        basePath: action.payload,
        ...state.um,
      };
    },
    setUm: (state, action) => {
      state.um.basePath = action.payload;
    },
    setParts: (state, action) => {
      state.settings = { ...state.settings, ...action.payload };
    },
    setLogbook: (state, action) => {
      state.settings = { ...state.settings, ...action.payload };
    },
    setUpApp: (state, action) => {
      state.basePath = { ...state.settings, ...action.payload };
    },
  },
});

export const selectApiBaseDomain = (state) => state.api.domain;
export const selectApiBasePath = (state) => state.api.basePath;
export const selectUmApiSettings = (state) => state.api.um;
export const selectPartsApiSettings = (state) => state.api.parts;

export const { setBasePath } = apiSlice.actions;
export default apiSlice.reducer;
