if (process.env.REACT_APP_LOCAL_DEV_EVN) {
  const apiInUse = 'api-integration';
  window.REACT_APP_NRL_ENV = 'integration';
  window.REACT_APP_NRL_PARTS_URL = `https://${apiInUse}.nrail.de/parts/api/v1`;
  window.REACT_APP_NRL_UM_URL = `https://${apiInUse}.nrail.de/um/v1`;
  window.REACT_APP_NRL_LOGBOOK_URL = `https://${apiInUse}.nrail.de/logbook/api/v1`;
  window.REACT_APP_NRL_BASE_URL = `https://${apiInUse}.nrail.de`;

  // urls
  window.REACT_APP_NRL_PARTS_APP_URL = `https://parts-${window.REACT_APP_NRL_ENV}.nrail.de`;
  window.REACT_APP_NRL_HOME_APP_URL = `https://home-${window.REACT_APP_NRL_ENV}.nrail.de`;
  window.REACT_APP_NRL_LIVE_APP_URL = `https://my-${window.REACT_APP_NRL_ENV}.nrail.de`;
  window.REACT_APP_NRL_SETTINGS_APP_URL = `https://accounts-${window.REACT_APP_NRL_ENV}.nrail.de`;
}

if (process.env.REACT_APP_LOCAL_DEV_EVN_MCKOY) {
  const apiInUse = 'api-development';
  window.REACT_APP_NRL_ENV = 'development';
  // window.REACT_APP_INITIAL_ENV = window.REACT_APP_NRL_ENV;
  window.REACT_APP_NRL_PARTS_URL = `https://${apiInUse}.nrail.de/parts/api/v1`;
  window.REACT_APP_NRL_UM_URL = `https://${apiInUse}.nrail.de/um/v1`;
  window.REACT_APP_NRL_LOGBOOK_URL = `https://${apiInUse}.nrail.de/logbook/api/v1`;
  window.REACT_APP_NRL_BASE_URL = `https://${apiInUse}.nrail.de`;

  // urls
  window.REACT_APP_NRL_PARTS_APP_URL = `https://parts-${window.REACT_APP_NRL_ENV}.nrail.de`;
  window.REACT_APP_NRL_HOME_APP_URL = `https://home-${window.REACT_APP_NRL_ENV}.nrail.de`;
  window.REACT_APP_NRL_LIVE_APP_URL = `https://my-${window.REACT_APP_NRL_ENV}.nrail.de`;
  window.REACT_APP_NRL_SETTINGS_APP_URL = `https://accounts-${window.REACT_APP_NRL_ENV}.nrail.de`;
}

if (process.env.REACT_APP_LOCAL_DEV_EVN_THOMAS) {
  const systemInUse = '-localhost';
  window.REACT_APP_NRL_PARTS_URL = `http://localhost:2000/api/v1`;
  window.REACT_APP_NRL_UM_URL = `http://localhost:3001/api/v1`;
  window.REACT_APP_NRL_LOGBOOK_URL = `https://api${systemInUse}.nrail.de/logbook/api/v1`;
  window.REACT_APP_NRL_BASE_URL = `https://api${systemInUse}.nrail.de`;

  // urls
  window.REACT_APP_NRL_PARTS_APP_URL = `https://parts-${window.REACT_APP_NRL_ENV}.nrail.de`;
  window.REACT_APP_NRL_HOME_APP_URL = `https://home-${window.REACT_APP_NRL_ENV}.nrail.de`;
  window.REACT_APP_NRL_LIVE_APP_URL = `https://my-${window.REACT_APP_NRL_ENV}.nrail.de`;
  window.REACT_APP_NRL_SETTINGS_APP_URL = `https://accounts-${window.REACT_APP_NRL_ENV}.nrail.de`;
}

if (window.REACT_APP_NRL_ENV !== 'production' && localStorage.getItem('NRL_BASE_URL')) {
  // window.REACT_APP_INITIAL_ENV = window.REACT_APP_NRL_BASE_URL;
  window.REACT_APP_NRL_BASE_URL = localStorage.getItem('NRL_BASE_URL') || '';
  window.REACT_APP_NRL_PARTS_URL = `${window.REACT_APP_NRL_BASE_URL}${localStorage.getItem('NRL_PARTS_PATH') || '/parts/api/v1'
    }`;
  window.REACT_APP_NRL_UM_URL = `${window.REACT_APP_NRL_BASE_URL}${localStorage.getItem('NRL_USER_MANAGEMENT_PATH') || '/um/v1'
    }`;
  window.REACT_APP_NRL_LOGBOOK_URL = `${window.REACT_APP_NRL_BASE_URL}/logbook/api/v1`;
}

if (process.env.REACT_APP_FIX_MUI_MSG) {
  const style = document.createElement('style');
  style.innerHTML = `.MuiDataGrid-main div[style*="position: absolute"][style*="z-index: 100000"] {
          display: none;
      }`;

  const ref = document.querySelector('title');
  ref?.parentNode?.insertBefore(style, ref);
}

console.devLog = () => { };

if (process.env.REACT_APP_LOCAL_DEV_EVN || localStorage.getItem('debugNrl')) {
  const styles = ['padding: 0.25em .5em', 'font-size: 1em', 'color: #FFF'].join(';');
  const colors = {
    green: '#50992b',
    blue: '#0074D9',
    orange: '#D96500',
    grey: '#4d4545',
  };

  console.devLog = (
    data,
    title = 'title not given',
    category = 'category not given',
    color = 'green',
    color2 = 'orange'
  ) => {
    // console.trace();
    // eslint-disable-next-line no-caller,no-restricted-properties
    // @ts-ignore
    const link = new Error().stack
      .split('\n')
      .filter((err) => err.match(/_callee\$\s\(/))
      .map((err) => err.replace(/[(\s)]|at _callee\$*/g, ''))
      .pop();
    // const link = new Error().stack.match(/_callee\$\s\(.*\)/g)[0].match(/http.*/g)[0];
    console.log(
      `%c%s%c%s%o`,
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      `${styles};background: ${colors[color]};`,
      category,
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      `${styles};background: ${colors[color2]};`,
      title,
      {
        data,
      },
      link
    );
  };
}
