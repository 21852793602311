import { ThemeProvider } from '@mui/material';
import CssBaseline from '@mui/material/CssBaseline';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Redirect, Route, BrowserRouter as Router, Switch } from 'react-router-dom';

// eslint-disable-next-line import/no-cycle
import DevSettings from '../../Apps/DevSettings';
import FrameLogo from '../../assets/svgs/frameLogo';
import PartsLogo from '../../assets/svgs/partsLogo';
import { NrlThemeResponsive } from '../../nrail-theme/materialUIThemeConfig';
import { isUserRefreshTokenSet } from '../../utils/user-auth-helpers';
import AppInfo from '../AppInfo';
import {
  fetchUserData,
  selectAccessToken,
  selectIsAccessTokenSet,
  selectIsNewLoginRequired,
} from '../authenticationViews/authenticationSlice';
import LoginPage from '../authenticationViews/Login';

const CurrentApplication = React.lazy(
  () => import(`../../Apps/${process.env.REACT_APP_NRL_PROJECT || 'frame'}`)
);
// const CurrentApplication = React.lazy(() => import(`../../Apps/${process.env.REACT_APP_NRL_PROJECT || 'frame'}`));

const applicationLogoMap: { [k: string]: JSX.Element } = {
  frame: <FrameLogo />,
  parts: <PartsLogo />,
  userManagement: <PartsLogo />,
};

type LoggedInUserProps = {
  application: string;
};

const LoggedInUser = ({ application }: LoggedInUserProps) => {
  const accessToken = useSelector(selectAccessToken);
  const dispatch = useDispatch();
  const applicationLogo = applicationLogoMap[application] || <FrameLogo />;

  const isAccessTokenSet = useSelector(selectIsAccessTokenSet);
  const [isLoggedIn, setIsLoggedIn] = useState(isUserRefreshTokenSet());
  const [isSessionInitialised, setIsSessionInitialised] = useState(false);
  const isNewLoginRequired = useSelector(selectIsNewLoginRequired);

  useEffect(() => {
    setIsLoggedIn(isUserRefreshTokenSet());
    if (!window.sessionStorage.getItem('activeOrganisationId') && isAccessTokenSet) {
      setIsSessionInitialised(false);
    }
  }, [isAccessTokenSet]);

  useEffect(() => {
    if (!isSessionInitialised && isAccessTokenSet && isLoggedIn) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      dispatch(fetchUserData({ accessToken }));
      setIsSessionInitialised(true);
    }
  }, [isAccessTokenSet, dispatch, accessToken, isSessionInitialised, isLoggedIn]);

  return (
    <Router>
      <ThemeProvider theme={NrlThemeResponsive}>
        <CssBaseline />
        <Switch>
          <Route
            exact
            path="/dev-configs"
            render={() =>
              window.REACT_APP_NRL_ENV !== 'production' ? <DevSettings /> : <Redirect to="/" />
            }
          />
          {/*<Route
            exact
            path="/reset-passkey"
            // eslint-disable-next-line react/no-unstable-nested-components
            render={() =>
              isLoggedIn ? (
                <Redirect to="/" />
              ) : (
                <LoginPage isResetPassword applicationLogo={applicationLogo} />
              )
            }
          />
          <Route
            exact
            path="/login"
            // eslint-disable-next-line react/no-unstable-nested-components
            render={() =>
              isLoggedIn ? (
                <Redirect to="/" />
              ) : (
                <LoginPage applicationLogo={applicationLogo} isResetPassword={undefined} />
              )
            }
          />
          <Route
            exact
            path="/app-info"
            // eslint-disable-next-line react/no-unstable-nested-components
            component={AppInfo}
          />*/}
          <Route
            path="/"
            // eslint-disable-next-line react/no-unstable-nested-components
            // public access
            /* render={() =>
              isLoggedIn || isNewLoginRequired ? <CurrentApplication /> : <Redirect to="/login" />
            } */
            component={CurrentApplication}
          />
        </Switch>
      </ThemeProvider>
    </Router>
  );
};

export default LoggedInUser;
