// eslint-disable-next-line import/no-cycle
import { store } from '../../index';
// eslint-disable-next-line import/no-cycle
import { setAccessToken } from '../../views/authenticationViews/authenticationSlice';
import { updateConfigs } from './parts';

let accessToken = null;

export const updateAllDependentsAccessToken = async (newAccessToken) => {
  accessToken = newAccessToken;
  await updateConfigs(newAccessToken).then(() => store.dispatch(setAccessToken(newAccessToken)));
};

export const isInitialTokenSet = () => !!accessToken;
