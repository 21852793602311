const getNrlButtonComponent = (theme) => ({
  MuiButton: {
    variants: [
      {
        props: { variant: 'lowerCase' },
        style: {
          textTransform: 'none',
          color: theme.palette.primary.main,
        },
      },
      {
        props: { variant: 'nrailSecondary', color: 'secondary' },
        style: {
          textTransform: 'none',
          color: theme.palette.primary.main,
          border: `2px dashed ${theme.palette.primary.main}`,
        },
      },
      {
        props: { variant: 'nrailWarning', color: 'secondary' },
        style: {
          textTransform: 'none',
          color: theme.palette.primary.main,
          border: `2px dashed ${theme.palette.primary.main}`,
        },
      },
    ],
  },
  MuiTypography: {
    defaultProps: {
      variantMapping: {
        nrlFormSectionLabel: 'p',
      },
    },
  },
  MuiChip: {
    styleOverrides: {
      colorDefault: {
        borderRadius: 4,
      },
      colorError: {
        color: theme.palette.error.main,
        backgroundColor: theme.palette.error.background,
        borderRadius: 4,
      },
      colorWarning: {
        color: theme.palette.warning.main,
        backgroundColor: theme.palette.warning.background,
        borderRadius: 4,
      },
      colorInfo: {
        color: theme.palette.info.main,
        backgroundColor: theme.palette.info.background,
        borderRadius: 4,
      },
      colorSuccess: {
        color: theme.palette.success.main,
        backgroundColor: theme.palette.success.background,
        borderRadius: 4,
      },
    },
  },
});

export default getNrlButtonComponent;
