import React from 'react';

import { Box } from '@mui/material';
import { ScaleLoader } from 'react-spinners';

const LoadingSpinner = () => (
  <Box
    sx={{
      height: '100%',
      width: '100%',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    }}>
    <ScaleLoader color="#1cdd43" loading />
  </Box>
);

export default LoadingSpinner;
