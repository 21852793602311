import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { decode, encode } from 'jwt-simple';
import { AccountsPersonsApiAxiosParamCreator } from 'nrail-user-management-api-client';
import hash from 'object-hash';

// import { store } from '../../app/store';
// eslint-disable-next-line import/no-cycle
import { getUMConfigs } from '../../apis/nRail/userManagement';
// eslint-disable-next-line import/no-cycle
import { getAccountAccessRights } from '../../apis/nRail/userManagement/authentication';
// eslint-disable-next-line import/no-cycle
import { getPersonAccount, getPersonAccountSettings } from '../../apis/nRail/userManagement/person';
import i18n, { DEFAULT_LOCALE_MAP } from '../../i18n';

const initialState = {
  accessToken: null,
  apps: [],
  newLoginRequired: null,
  isAccessTokenSet: null,
  activeOrganisationId: null,
  navHashInfo: '',
  settings: {
    language: localStorage.getItem('myLocale'),
  },
  userAvatarUrlDefault: '/assets/images/default-user-image.png',
  userAvatarUrl: null,
  accessRights: {},
  organisations: [],
  status: 'idle',
  error: null,
  spareParts: [],
  workshop: [],
  consumable: [],
  services: []
};

export const fetchUserData = createAsyncThunk('user/fetchUserData', async (inputObj) => {
  let { accountId, oldLoginId, accountName } = decode(localStorage.getItem('userToken'), '', true);
  // taking localstorage settings that the user last used on this current browser
  const settings = (await getPersonAccountSettings()) || {};
  const tempAccessRights = await getAccountAccessRights();

  // const settings = initialState?.user?.settings;
  if (initialState.settings.language) {
    settings.language = initialState.settings.language;
  }
  if (settings.language?.length < 3) {
    settings.language = DEFAULT_LOCALE_MAP[settings.language] || 'de-DE';
  }

  i18n.changeLanguage(settings.language);
  if (settings.language) {
    localStorage.setItem('myLocale', settings.language);
  }

  let organisations = [];
  let { accessToken, activeOrganisationId } = inputObj;

  if (accessToken) {
    try {
      accessToken = decode(accessToken, '', true);
      accountId = accessToken.accountId;
      oldLoginId = accessToken.oldLoginId || 0;
      const orgWithPartsAccess = getAccountsIdsWithPartsAccess(tempAccessRights);

      console.devLog(process.env);
      console.devLog(tempAccessRights, process.env.PATH);
      console.devLog(accessToken.organisations.filter(({ id }) => orgWithPartsAccess.includes(id)));
      console.devLog(organisations);
      organisations = accessToken.organisations
        .filter(({ id }) => orgWithPartsAccess.includes(id))
        .sort((a, b) => a?.name.localeCompare(b?.name));
    } catch (e) {
      console.dir(e);
    }
  }
  const isIdValid = (tempId) => tempId && !!organisations.find(({ id }) => id === tempId);
  const params = new URLSearchParams(window.location.search);
  // let printSrc = 'initial';

  const idHashFromUrl = params.get('origin');
  if (!activeOrganisationId) {
    // printSrc = 'id from url hash';
    // console.devLog(printSrc);
    // id from url hash
    if (idHashFromUrl && localStorage.getItem(idHashFromUrl)) {
      try {
        const userSessionInfoJwt = localStorage.getItem(idHashFromUrl);
        const decodedUserSessionInfo = decode(userSessionInfoJwt, accountName, true);
        activeOrganisationId = decodedUserSessionInfo?.activeOrganisationId;
      } catch (e) {
        activeOrganisationId = localStorage.getItem('lastSelectedOrganisationId');
        params.set('origin', hash(activeOrganisationId));
        window.location = `${window.location.pathname}${params.toString()}`;
      }
    }

    if (!isIdValid(activeOrganisationId)) {
      // id from url
      // printSrc = 'id from url';
      // console.devLog(printSrc, window.location.pathname);
      const possibleOrgId = window.location.pathname?.match(/[a-zA-Z-\d]+/i);
      activeOrganisationId = possibleOrgId ? possibleOrgId[0] : null;

      if (!isIdValid(activeOrganisationId)) {
        // id from url failed --> get from sessionStorage
        // printSrc = 'id from url failed --> get from sessionStorage';
        // console.devLog(printSrc);
        activeOrganisationId = sessionStorage.getItem('activeOrganisationId');

        if (!isIdValid(activeOrganisationId)) {
          // id from url failed --> get from localStorage
          // printSrc = 'id from url failed --> get from localStorage';
          // console.devLog(printSrc);
          activeOrganisationId = localStorage.getItem('lastSelectedOrganisationId');

          if (!isIdValid(activeOrganisationId)) {
            // id from storage failed --> get first from org list
            // printSrc = 'id from storage failed --> get first from org list';
            // console.devLog(printSrc);
            activeOrganisationId = organisations[0]?.id;
            sessionStorage.setItem('activeOrganisationId', activeOrganisationId);
          }
        }
      }
    }
  }

  const navHashInfo = hash(activeOrganisationId);
  const tempUserSessionJwt = encode({ activeOrganisationId }, accountName);
  localStorage.setItem(navHashInfo, tempUserSessionJwt);

  if (
    (window.location.pathname.match('^/$') || window.location.pathname.match(/marketplace/)) &&
    navHashInfo &&
    idHashFromUrl !== navHashInfo
  ) {
    params.set('origin', navHashInfo);
    window.history.replaceState(
      undefined,
      undefined,
      `${window.location.pathname}${params.toString() ? `?${params.toString()}` : ''}`
    );
  }

  const umConfigs = await getUMConfigs();
  let userData = await getPersonAccount(accountId);

  if (userData?.resultCode && userData?.resultCode === 'err_invalid_access_rights') {
    userData = { accessToken: null, isAccessTokenSet: null };
  }
  const userAvatarUrl = await AccountsPersonsApiAxiosParamCreator(
    umConfigs
  ).getPersonAccountAvatarImage(accountId, userData.avatarImageUrlSignature);

  const { status } = await fetch(`${umConfigs.basePath}${userAvatarUrl.url}`).catch((e) => {
    console.dir(e);
    return {};
  });

  const accessRights = tempAccessRights[activeOrganisationId] || {};
  const superUserAccessRights = {};
  Object.values(tempAccessRights).map((rights) =>
    Object.keys(rights)
      .filter((rightsKey) => rightsKey.match(/\/all/))
      .forEach((rightsKeyAll) => (superUserAccessRights[rightsKeyAll] = rights[rightsKeyAll]))
  );

  if (process.env.REACT_APP_DEBUG_ACCESS_RIGHTS) {
    console.devTableLog(accessRights);
    console.devTableLog(superUserAccessRights);
  }

  const initials = `${userData?.firstName?.toUpperCase().charAt(0)}${userData?.lastName
    ?.toUpperCase()
    .charAt(0)}`;

  return {
    ...userData,
    initials,
    activeOrganisationId,
    settings,
    navHashInfo,
    oldLoginId,
    organisations,
    superUserAccessRights,
    accessRights,
    userAvatarUrl:
      status === 200
        ? `${umConfigs.basePath}${userAvatarUrl.url}`
        : initialState.userAvatarUrlDefault,
  };
});

const getAccountsIdsWithPartsAccess = (accessRights = {}) => {
  const idsToKeep = {};
  let isAdmin = false;

  for (const accountId in accessRights) {
    const orgPartsAccessArray = Object.keys(accessRights[accountId]).filter((rights) => {
      const hasAccessToParts = !!rights.match(/^parts/);
      if (isAdmin) {
        return true;
      }

      if (hasAccessToParts) {
        idsToKeep[accountId] = true;
      }

      isAdmin = !!rights.match(/^parts\/.*\/all/);
      return hasAccessToParts;
    });
  }

  return Object.keys(isAdmin ? accessRights : idsToKeep);
};

export const fetchUserImage = createAsyncThunk('persons/fetchUserImage', async () => { });

const publicRouteCheck = (url) => {
  const routes = [
    'marketplace',
    'vehicles',
    'spareparts',
    'consumables',
    'workshop',
    'services/',
    'jobs',
  ].join(')|(');
  const re = new RegExp(`(${routes})|(^\/$)`);
  return re.exec(url) !== null;
};

const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setSpareParts: (state, action) => {
      state.spareParts = action.payload;
    },
    setWorkshop: (state, action) => {
      state.workshop = action.payload;
    },
    setConsumable: (state, action) => {
      state.consumable = action.payload;
    },
    setServices: (state, action) => {
      state.services = action.payload;
    },
    changeUserLanguage: (state, action) => {
      if (action.payload) {
        localStorage.setItem('myLocale', action.payload);
      }
      // eslint-disable-next-line no-param-reassign
      state.settings.language = action.payload;
    },
    addUserProps: (state, action) => {
      if (action.payload) {
        localStorage.setItem('myLocale', action.payload);
      }
      // eslint-disable-next-line no-param-reassign
      state.settings = { ...state.settings, ...action.payload };
    },
    setUserActiveOrganisation: (state, action) => {
      sessionStorage.setItem('activeOrganisationId', action.payload);
      // eslint-disable-next-line no-param-reassign
      state.activeOrganisationId = action.payload;
      state.navHashInfo = action.payload;
    },
    setUserAvatarUrlRefresh: (state, action) => {
      // eslint-disable-next-line no-param-reassign
      state.userAvatarUrl = `${state.userAvatarUrl.replace(
        /&refresh=.+/,
        ''
      )}&refresh=${`${Date.now()}`}`;
    },
    setDefaultUserImage: (state, action) => {
      // eslint-disable-next-line no-param-reassign
      state.userAvatarUrl = initialState.userAvatarUrlDefault;
    },
    setUserHasNoAccess: (state, action) => {
      state.userHasAccessToPlatform = action.payload;
    },
    setUserApps: (state, action) => {
      state.apps = action.payload;
    },
    setAccessToken: (state, action) => {
      // eslint-disable-next-line no-param-reassign
      if (action.payload?.ignoreIsAccessTokenSet) {
        state.accessToken = window.localStorage.getItem('userToken') && action.payload.accessToken;
      } else {
        state.accessToken = window.localStorage.getItem('userToken') && action.payload;
        state.isAccessTokenSet = !!window.localStorage.getItem('userToken');
      }

      // if (!window.location.pathname.match(/(marketplace)|(vehicles)|(^\/$)/)) {
      //   state.newLoginRequired = !window.localStorage.getItem('userToken');
      // }
      if (!publicRouteCheck(window.location.pathname)) {
        state.newLoginRequired = !window.localStorage.getItem('userToken');
      }
    },
    setNavHashInfo: (state, action) => {
      // eslint-disable-next-line no-param-reassign
      state.navHashInfo = action.payload;
    },

    setNewLoginRequired: (state, action) => {
      // eslint-disable-next-line no-param-reassign
      // if (!window.location.pathname.match(/(marketplace)|(vehicles)|(^\/$)/)) {
      //   state.newLoginRequired = action.payload;
      // }
      if (!publicRouteCheck(window.location.pathname)) {
        state.newLoginRequired = action.payload;
      }
    },
    resetUser: () => initialState,
  },
  extraReducers: {
    [fetchUserData.fulfilled]: (state, action) => {
      // eslint-disable-next-line no-param-reassign
      state.status = 'succeeded';
      Object.keys(action.payload).forEach((key) => {
        // eslint-disable-next-line no-param-reassign
        state[key] = action.payload[key];
      });
    },
  },
  [fetchUserData.pending]: (state) => {
    // eslint-disable-next-line no-param-reassign
    state.status = 'loading';
  },
  [fetchUserData.rejected]: (state, action) => {
    // eslint-disable-next-line no-param-reassign
    state.status = 'failed';
    // eslint-disable-next-line no-param-reassign
    state.error = action.error.message;
  },
});

export const selectStore = (state) => state;
export const selectUser = (state) => state.user;
export const selectAccessToken = (state) => state.user.accessToken;
export const selectIsAccessTokenSet = (state) => state.user.isAccessTokenSet;
export const selectIsNewLoginRequired = (state) => state.user.newLoginRequired;
export const selectUserAccountId = (state) => state.user.accountId;
export const selectUserAccessRightsSet = (state) => state.user.accessRights;
export const selectNavHashInfo = (state) => state.user.navHashInfo;
export const selectUserHasAccessToPlatform = (state) => state.user.userHasAccessToPlatform;
export const selectUserApplications = (state) => state.user.apps;
export const selectSpareParts = (state) => state.user.spareParts;
export const selectWorkshop = (state) => state.user.workshop;
export const selectConsumable = (state) => state.user.consumable;
export const selectServices = (state) => state.user.services;

export const {
  changeUserLanguage,
  addUserProps,
  setUserActiveOrganisation,
  setUserAvatarUrlRefresh,
  setDefaultUserImage,
  setAccessToken,
  setUserHasNoAccess,
  setNewLoginRequired,
  resetUser,
  setUserApps,
  setSpareParts,
  setWorkshop,
  setConsumable,
  setServices
} = userSlice.actions;

export default userSlice.reducer;
