import { configureStore } from '@reduxjs/toolkit';

import apiSliceReducer from '../apis/nRail/apis-redux-slice';
import PartsFiltersSlice from '../Apps/parts/containers/PartsFiltersSlice';
import cartSliceReducer from '../Apps/parts/views/marketplace/cartManagement.reducer';
import detailedListingReducer from '../Apps/parts/views/marketplace/DetailedPartListing/detailedListingSlice';
import partListingReducer from '../Apps/parts/views/marketplace/PartListingsOverview/partListingSlice';
// eslint-disable-next-line import/no-cycle
import userSliceReducer from '../views/authenticationViews/authenticationSlice';

const store = configureStore({
  reducer: {
    partListing: partListingReducer,
    detailedListing: detailedListingReducer,
    user: userSliceReducer,
    cart: cartSliceReducer,
    api: apiSliceReducer,
    filtersListing: PartsFiltersSlice,
  },
});
export default store;
