import lsChangeDetector from 'js-localstorage-change-detector';
import { decode } from 'jwt-simple';
import debounce from 'lodash.debounce';
import {
  AccountsApi,
  AccountsPersonsApi,
  AccountsSettingsApi,
  AuthBasicApi,
  AuthPasskeyApi,
  Configuration,
  RightsObjectsApi,
  RolesApi,
} from 'nrail-user-management-api-client';

import { store } from '../../../index';
import { cleanUpLocalStorage } from '../../../utils/helperUtilities';
import {
  resetUser,
  setAccessToken,
  setNewLoginRequired,
  setUserHasNoAccess,
} from '../../../views/authenticationViews/authenticationSlice';
import { selectApiBasePath } from '../apis-redux-slice';
// eslint-disable-next-line import/no-cycle
import { updateAllDependentsAccessToken } from '../index';

// eslint-disable-next-line import/no-cycle

// Make a call. If it returns a 401 error, the refreshAuthLogic will be run,
// and the request retried with the new token
let accessTokenIssueTime = null;
let accessTokenExpiresAt = null;

const basePath = window?.REACT_APP_NRL_UM_URL;

let refreshTokenTimeoutId;
let accessTokenIntervalId;

let configurationUserManagement = new Configuration({ basePath });

export const updateConfigs = async (accessToken) => {
  configurationUserManagement = new Configuration({ basePath, accessToken });
};

let apiCache = {
  currentToken: '',
};

const apiTypeMap = {
  umConfigs: Configuration,
  basicApi: AuthBasicApi,
  passkeyApi: AuthPasskeyApi,
  accPersonApi: AccountsPersonsApi,
  accSettingsApi: AccountsSettingsApi,
  rightsObj: RightsObjectsApi,
  rolesApi: RolesApi,
  accountsApi: AccountsApi,
};

const getApi = async (apiName) => {
  const currentToken = await configureRequestHeaders();

  if (apiCache.currentToken === '' || apiCache.currentToken !== currentToken) {
    await updateConfigs(currentToken);
    apiCache = { currentToken };
  }

  if (apiCache[apiName]) {
    return apiCache[apiName];
  }

  apiCache[apiName] = await new apiTypeMap[apiName](configurationUserManagement);

  return apiCache[apiName];
};

const timeLeftOnTokenInMilliSeconds = (token) => {
  if (token) {
    try {
      const { exp } = decode(token, '', true);
      const timeLeftInSeconds = exp * 1000 - Date.now();
      return Math.max(timeLeftInSeconds, 100);
    } catch (e) {
      console.devLog('invalid token');
    }
    console.devLog('missing refresh token');
  }
  return 0;
};

window.addEventListener('load', (event) => {
  if (window.localStorage.getItem('userToken')) {
    refreshTokenTimeoutId = setTimeout(() => {
      configureRequestHeaders().then(() => { });
    }, timeLeftOnTokenInMilliSeconds(window.localStorage.getItem('userToken')));
  }
});

const setAccessTokenTimeout = () => {
  if (accessTokenIntervalId) {
    clearTimeout(accessTokenIntervalId);
  }

  if (!store) {
    accessTokenIntervalId = setTimeout(setAccessTokenTimeout, 200);
    return;
  }

  configureRequestHeaders()
    .then((token) => {
      if (token) {
        accessTokenIntervalId = setTimeout(
          setAccessTokenTimeout,
          // 1000 * 5 // test time
          timeLeftOnTokenInMilliSeconds(token)
        );
      } else {
        console.devLog('failed', token);
      }
    })
    .catch(() => {
      console.log('err missed');
    });
};

export const getBasicAuthRefreshApi = (accessToken = window.localStorage.getItem('userToken')) =>
  new AuthBasicApi(
    new Configuration({
      basePath,
      accessToken,
    })
  );

let fetching = false;
export const configureRequestHeaders = async (
  refreshToken = window.localStorage.getItem('userToken'),
  forceAccessTokenRefresh = false,
  setRefreshToken = false
) => {
  // console.devLog('configureRequestHeaders::');
  if (!refreshToken) {
    if (window.location.pathname !== '/login') {
      accessTokenIssueTime = null;
      accessTokenExpiresAt = null;

      if (accessTokenIntervalId) {
        clearTimeout(accessTokenIntervalId);
      }
      if (refreshTokenTimeoutId) {
        clearTimeout(refreshTokenTimeoutId);
      }
      store?.dispatch(setAccessToken({ accessToken: null, ignoreIsAccessTokenSet: true }));
    }
    // throw 'refresh token missing';
    return null;
  }

  if (!store) {
    setTimeout(
      () =>
        configureRequestHeaders(
          refreshToken || undefined,
          forceAccessTokenRefresh || undefined,
          setRefreshToken || undefined
        ),
      200
    );
    // throw 'store not ready';
    return null;
  }

  const { user } = store.getState();
  let { accessToken } = user;

  if (!refreshToken && accessToken) {
    store?.dispatch(setAccessToken(null));
    store?.dispatch(resetUser());
    return;
  }
  let decodeRefreshToken;
  let decodeAccessToken;

  try {
    decodeAccessToken = decode(accessToken, '', true);
    decodeRefreshToken = decode(refreshToken, '', true);
  } catch (e) {
    accessToken = null;
  }

  if (accessToken) {
    const accessTokenValidityInSeconds = 30000; // invalidate after 30 sec before exp
    const refreshTokenValidityInSeconds = 60; // invalidate after 30 sec before exp
    // const accessTokenValidityInSeconds = 1000 * 60 * 9.75; // invalidate after 30 sec before exp
    // const refreshTokenValidityInSeconds = 60 * 29.5; // invalidate after 30 sec before exp

    if (
      decodeRefreshToken?.exp &&
      decodeAccessToken?.iat &&
      Math.trunc(decodeRefreshToken.exp - decodeAccessToken.iat) <= refreshTokenValidityInSeconds
    ) {
      console.devLog(
        'refresh token exp: ',
        Math.trunc(accessTokenExpiresAt - new Date().getTime())
      );
      cleanUpLocalStorage();
      store.dispatch(setNewLoginRequired(true));
      store.dispatch(setAccessToken({ accessToken: null, ignoreIsAccessTokenSet: true }));
      accessTokenIssueTime = null;
      accessTokenExpiresAt = null;
      return null;
    }

    if (
      accessTokenExpiresAt &&
      Math.trunc(accessTokenExpiresAt - new Date().getTime()) <= accessTokenValidityInSeconds
    ) {
      console.devLog('access token exp: ', Math.trunc(accessTokenExpiresAt - new Date().getTime()));
      accessTokenIssueTime = null;
      accessTokenExpiresAt = null;
      accessToken = null;
    }
  }

  const fetchTokenDebounced = async () => {
    const { data, response } = await getBasicAuthRefreshApi(refreshToken)
      .createAccessToken()
      .catch((error) => error);

    if (response && [401].includes(response.status)) {
      if (['err_unauthorized', 'err_invalid_access_rights'].includes(response.data?.resultCode)) {
        store?.dispatch(setAccessToken(null));
      }

      cleanUpLocalStorage();
      accessTokenIssueTime = null;
      accessTokenExpiresAt = null;
      store.dispatch(setAccessToken({ accessToken: null, ignoreIsAccessTokenSet: true }));
      // throw new Error('invalid refresh token');
    }

    if (response && response.status > 204) {
      return null;
    }

    if (data && data.accessToken) {
      try {
        const { exp, iat, accountId } = decode(data.accessToken, '', true);
        const refreshTokenDecoded = decode(refreshToken, '', true);

        if (accountId) {
          const tempAccPersonApi = await new AccountsApi(
            new Configuration({
              basePath,
              accessToken: data.accessToken,
            })
          );

          const hasAccessToParts = await tempAccPersonApi
            .getAccountAccessRights(accountId)
            .then(({ data }) =>
              data.accessRights.map(({ accessRights }) =>
                accessRights.filter((key) => key.rightsObjectName.match('parts/listings'))
              )
            )
            .then((listOfOrgsWithAccess) => !!listOfOrgsWithAccess.find((arr) => arr.length > 0))
            .catch((e) => {
              accessToken = null;
              console.devLog('bad token');
            });

          if (hasAccessToParts) {
            if (setRefreshToken) {
              localStorage.setItem('userToken', refreshToken);
              setRefreshTokenTimeout();
            }
            accessToken = data.accessToken;
          } else {
            accessToken = null;
            store.dispatch(setUserHasNoAccess(false));
          }

          if (hasAccessToParts) {
            // accounting for user pc is set to the future or past
            const expTimeRelativeToAccessToken = (exp - iat) * 1000;
            accessTokenExpiresAt = new Date().getTime() + expTimeRelativeToAccessToken;
            setAccessTokenTimeout(setAccessTokenTimeout, 2000);
          }
        }
      } catch (e) {
        console.dir(e);
      }
    }

    if (accessToken) {
      store.dispatch(setAccessToken(accessToken));
    }
    return null;
  };

  if (!accessToken || forceAccessTokenRefresh) {
    if (!fetching) {
      fetching = true;
      await fetchTokenDebounced();
    } else if (refreshToken) {
      return new Promise((resolve, reject) => {
        setTimeout(() => resolve(configureRequestHeaders()), 150);
      });
    }
  }

  if (accessToken) {
    configurationUserManagement = new Configuration({
      basePath,
      accessToken,
    });

    await updateAllDependentsAccessToken(accessToken);
  }

  if (fetching) {
    setTimeout(() => (fetching = false), 500);
  }
  return accessToken;
};

window.addEventListener('blur', () => {
  if (accessTokenIntervalId) {
    clearTimeout(accessTokenIntervalId);
  }
  if (refreshTokenTimeoutId) {
    clearTimeout(refreshTokenTimeoutId);
  }
});

window.addEventListener('focus', () => {
  if (accessTokenIntervalId) {
    clearTimeout(accessTokenIntervalId);
  }
  if (refreshTokenTimeoutId) {
    clearTimeout(refreshTokenTimeoutId);
  }
  setRefreshTokenTimeout();
});

lsChangeDetector.addChangeListener('onChange', 'userToken', (name, value) => {
  setRefreshTokenTimeout(!!value);
});

const setRefreshTokenTimeout = (forceAccessTokenUpdate = false) => {
  if (!window.location.pathname?.match('dev-configs')) {
    if (refreshTokenTimeoutId) {
      clearTimeout(refreshTokenTimeoutId);
    }
    configureRequestHeaders(undefined, forceAccessTokenUpdate).then(() => {
      if (window.localStorage.getItem('userToken')) {
        refreshTokenTimeoutId = setTimeout(
          setRefreshTokenTimeout,
          // 1000 * 40 // test time
          timeLeftOnTokenInMilliSeconds(window.localStorage.getItem('userToken'))
        );
      }
    });
  }
};

setRefreshTokenTimeout();

// LocalStorage
export const getUMConfigs = async () => configurationUserManagement;
export const getAuthenticationApi = async () => getApi('basicApi');
export const getPassKeyApi = async () => getApi('passKeyApi');
// LogbookEntriesApiAxiosParamCreator);

export const getAccPersonApi = async () => getApi('accPersonApi');
export const getAccSettingsApi = async () => getApi('accSettingsApi');

export const getRolesApi = async () => getApi('rolesApi');
export const getRightsObj = async () => getApi('rightsObj');
export const getAccountsApi = async () => getApi('accountsApi');
export { basePath };
