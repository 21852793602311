// eslint-disable-next-line import/no-cycle
import { getAccPersonApi, getAccSettingsApi } from './index';
// Persons

export const getPersonAccount = async (accountId) => {
  const { data } = await getAccPersonApi().then((api) =>
    api.getPersonAccount(accountId).catch(({ response }) => {
      if (response && [401].includes(response.status)) {
        return { data: response.data };
      }
      return null;
    })
  );
  return data || {};
};

export const getPersonAccountSettings = async () => {
  const { data } = await getAccSettingsApi().then((api) => api.getAccountSettings().catch(() => ({ data: {} })));
  if (typeof data !== 'object') {
    return { data };
  }
  return data;
};

export const UpdatePersonAccountSettings = async (accountSetting) =>
  (await getAccSettingsApi()).updateAccountSettings(accountSetting);

export const addToPersonAccountSettings = async (accountSetting) => {
  const storedSetting = await getPersonAccountSettings();
  return (await getAccSettingsApi()).updateAccountSettings({ ...storedSetting, ...accountSetting });
};

export const getPersonAccounts = async (queryParams = { orderby: ['accountName'] }) => {
  const { search, top, skip, filter, count, orderby, select } = queryParams;
  const { data } = await (await getAccPersonApi())
    .getPersonAccounts(search, top, skip, filter, count, orderby, select)
    .catch((e) => ({
      data: { message: `${e}` },
    }));

  return data;
};

export const createPersonAccount = async (personData) => {
  const { data } = await (await getAccPersonApi()).createPersonAccount(personData).catch((e) => ({
    data: { message: `${e}` },
  }));

  return data;
};

export const updatePersonAccount = async (accountId, updateInfo) => {
  const { status } = await getAccPersonApi().then((api) =>
    api.updatePersonAccount(accountId, updateInfo).catch((e) => ({
      status: { message: `${e}` },
    }))
  );
  return { status };
};

export const updatePersonAccountAvatarImage = async (accountId, avatarImage) =>
  (await getAccPersonApi()).updatePersonAccountAvatarImage(accountId, { avatarImage });

export const deletePersonAccount = async (accountId) =>
  (await getAccPersonApi()).deletePersonAccountAvatarImage(accountId).catch((err) => ({
    status: err.status,
    message: err.message,
  }));
