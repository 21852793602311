const IS_DEV = process.env.NODE_ENV === 'development';
const IS_TEST = process.env.NODE_ENV === 'test';

/**
 * @function
 * @description is used to generate html standard id names
 * @param {string} name
 * @param {string} joinDelimiter - The item to symbol to be used when joining the the string back together
 * @return {string}
 */
export const getComponentHtmlName = (name = '', joinDelimiter = '-') =>
  name
    .replace(/[A-Z]/g, (v) => ` ${v.toLowerCase()}`)
    .trim()
    .split(/\s+/)
    .join(joinDelimiter);

/**
 * @function
 * @description used to generate the name that matches the the component in the dome based on the component displayName
 * @param {string} componentDisplayName
 * @param {string} name
 * @return {string}
 */
export const generateAttributeTargetName = (componentDisplayName, name = '') =>
  `${getComponentHtmlName(componentDisplayName || '')}-${getComponentHtmlName(name)}`;

/**
 * @function
 * @description generates the test attribute used for automated testing testing
 * @param {string} componentDisplayName
 * @param {string} name
 * @return {{' data-test': string}|{}}
 */
export const generateTestAttribute = (componentDisplayName, name = '') =>
  IS_TEST || IS_DEV ? { 'data-test': generateAttributeTargetName(componentDisplayName, name) } : {};

export const sleep = function (ms) {
  return new Promise((resolve) => setTimeout(resolve, ms));
};

export const cleanUpLocalStorage = () => {
  ['userToken', 'userMsgTs', 'userSuggestReloadTs', 'NRL_DELIVERY_ADDRESSES'].forEach((item) =>
    localStorage.removeItem(item)
  );
};

export const cleanUpLocalStorage_TBC = () => {
  const keysToKeep = ['i18nextLng', 'currentApplicationVersion'];
  Object.entries(localStorage)
    .map((curr) => curr[0])
    .filter((curr) => !curr.match(/^NRL_/))
    .filter((curr) => !keysToKeep.includes(curr))
    .forEach((item) => localStorage.removeItem(item));
};
