import { createSlice } from '@reduxjs/toolkit';

const getCartFromStorage = () => JSON.parse(localStorage.getItem('cart') || '{}');
const defaultDeliveryAddress = {
  key: 'org-add-key-1',
  isPrimary: true,
  company: 'NorthRail GmbH',
  department: 'König Kontor',
  firstName: '',
  lastName: '',
  street: '  Königstraße',
  streetNumber: '28',
  city: 'Hamburg',
  postalCode: '22767',
  country: 'Deutschland',
};

const calculateTotal = (items) => {
  const total =
    Object.values(items)
      .map(({ price_euroCent, quantity }) => price_euroCent * quantity)
      .reduce((previousValue, currentValue) => previousValue + currentValue, 0) || 0;
  return total;
};

const cartInit = JSON.parse(localStorage.getItem('cart') || '{}');
const temOrderList = JSON.parse(localStorage.getItem('temOrderList') || '[]');
const initialState = {
  orderListDetails: {
    orderListId: '123e4567-e89b-12d3-a456-426655440000',
    countElements: 0,
  },
  items: cartInit?.items || {},
  deliveryAddress: cartInit?.deliveryAddress || { ...defaultDeliveryAddress },
  total: calculateTotal(cartInit.items || {}),
  orderList: temOrderList || [],
};
const saveToLocalStorage = ({ items = undefined, deliveryAddress = undefined }) => {
  const storedCart = getCartFromStorage();
  if (items) {
    storedCart.items = { ...items };
  }
  if (deliveryAddress) {
    storedCart.deliveryAddress = deliveryAddress;
  }
  localStorage.setItem('cart', JSON.stringify(storedCart));
  // window.dispatchEvent(new Event('storage'));
};

const saveCartData = (state, action, operation) => {
  const newCartList = { ...state.items };
  if (operation === 'add') {
    newCartList[action?.payload?.listingId] = action?.payload;
  } else if (operation === 'remove') {
    delete newCartList[action?.payload];
  }

  saveToLocalStorage({ items: newCartList });

  return newCartList;
};

const cartSlice = createSlice({
  name: 'cart',
  initialState,
  reducers: {
    addToOrderList: (state, action) => {
      const newTempOrderLst = JSON.parse(localStorage.getItem('temOrderList') || '[]');
      state.orderList = [
        ...newTempOrderLst,
        {
          id: (Math.random() + 1).toString(36).substring(2).toUpperCase(),
          ordername: (Math.random() + 1).toString(36).substring(2).toUpperCase(),
          status: ['inProgress', 'complete'][Math.floor(Math.random() * 2)],
          ordernumber: (Math.random() + 1).toString(36).substring(2).toUpperCase(),
          orderTotal: state.total,
          items: state.items,
          deliveryAddress: state.deliveryAddress,
        },
      ];

      localStorage.setItem('temOrderList', JSON.stringify(state.orderList));
      localStorage.setItem('cart', JSON.stringify({}));
      state.items = {};
      state.deliveryAddress = { ...defaultDeliveryAddress };
      state.total = calculateTotal(cartInit.items || {});
    },
    addToCartList: (state, action) => {
      state.items = saveCartData(state, action, 'add');
      state.total = calculateTotal(state.items);
    },
    deleteFromCartList: (state, action) => {
      state.items = saveCartData(state, action, 'remove');
      state.total = calculateTotal(state.items);
    },
    updateCartMeta: (state, action) => {
      state.orderListDetails = action.payload;
    },
    updateDeliveryAddress: (state, action) => {
      state.deliveryAddress = action.payload;
      const { deliveryAddress } = state;
      saveToLocalStorage({ deliveryAddress });
    },
  },
});

const { reducer, actions } = cartSlice;

export const selectCart = (state) => state.cart?.items || {};
export const selectCartDetails = (state) => state.cart?.orderListDetails || {};
export const selectCartTotal = (state) => state.cart?.total || 0;
export const selectDeliveryAddress = (state) => state.cart?.deliveryAddress;
export const selectOrders = (state) => state?.cart?.orderList;
export const {
  addToCartList,
  deleteFromCartList,
  updateDeliveryAddress,
  addToOrderList,
  updateCartMeta,
} = actions;
export default reducer;
