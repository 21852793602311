import { createTheme, responsiveFontSizes } from '@mui/material/styles';

import NrlComponents from './components';
import getNrlTypography from './components/typography';

// import { deDE as coreDeDE } from '@mui/material/locale';
// const ZOOM_FACTOR = 1;
const ZOOM_FACTOR = window.localStorage.getItem('zoomActive') || 1;
// console.devLog(Math.floor(16 * ZOOM_FACTOR));

const palette = {
  primary: {
    // green
    main: '#06DE3A',
    light: '#EBFDEF',
    contrastText: '#FFFFFF',
    background: '#E0FBE7',
  },
  secondary: {
    main: '#11cb5f',
  },
  error: {
    // red
    main: '#F44336',
    contrastText: '#FFFFFF',
    background: '#FDECEB',
  },
  warning: {
    // orange
    main: '#FF9800',
    contrastText: '#FFFFFF',
    background: '#FFF5E6',
  },
  info: {
    // blue
    main: '#2196F3',
    contrastText: '#FFFFFF',
    background: '#E9F5FE',
  },
  infoChip: {
    // blue
    light: '#EBFDEF',
    contrastText: '#FFFFFF',
    main: '#2196F3',
    background: '#2196F3',
  },
  success: {
    // green
    main: '#06DE3A',
    contrastText: '#FFFFFF',
    background: '#E0FBE7',
  },
  not_submitted: {
    // orange
    main: '#FF9800',
    contrastText: '#FFFFFF',
    background: '#FFF5E6',
  },
  published: {
    // green
    main: '#06DE3A',
    contrastText: '#FFFFFF',
    background: '#E0FBE7',
  },
  disabled: {
    // grey
    main: '#000000db',
    contrastText: '#FFFFFF',
    background: '#E0FBE7',
  },
  disabledGray: {
    // grey
    main: '#E0E0E0',
    contrastText: '#000000DE',
    background: '#E0E0E0',
  },

  black: {
    // black
    main: '#000',
    contrastText: '#FFFFFF',
    background: '#E0FBE7',
  },

  // links: {
  //   main: '#1298DE',
  // },
  // status: {
  //   danger: '#FF0000',
  //   warning: '#FF6600',
  // },
};

const theme = createTheme(
  {
    typography: getNrlTypography({ palette }, ZOOM_FACTOR),
    palette,
    components: NrlComponents({ palette }),
  }
  // deDE
  // { coreDeDE }
);

export const NrlThemeResponsive = responsiveFontSizes(theme);
export const NrlTheme = responsiveFontSizes(theme);

export default theme;
