import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

const initialState = {
  workshopCategories: JSON.parse(localStorage.getItem('CategoryList') || '[]') || [],
};

export const PartsFiltersSlice = createSlice({
  name: 'filtersListing',
  initialState,
  reducers: {
    setWorkshopCategories: (state, action) => {
      state.workshopCategories = action.payload;
      localStorage.setItem('CategoryList',JSON.stringify(action.payload || []))
    },
  },
});

export const workshopCategories = (state) => state.filtersListing.workshopCategories;
export const { setWorkshopCategories } = PartsFiltersSlice.actions;

export default PartsFiltersSlice.reducer;
