import { useTheme } from '@emotion/react';
import {
  Box,
  Button,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormLabel,
  Grid,
  Switch,
  TextField,
  Typography,
} from '@mui/material';
import lsChangeDetector from 'js-localstorage-change-detector';
import React, { useState } from 'react';
import { Helmet } from 'react-helmet';

import { getBasicAuthRefreshApi } from '../../apis/nRail/userManagement';
import { getDevSettings } from '../../constants/mainLayout';

['NRL_PARTS_PATH', 'NRL_USER_MANAGEMENT_PATH', 'NRL_BASE_URL'].forEach((prop) =>
  lsChangeDetector.addChangeListener('onChange', prop, (name, value) => {
    window.location = window.location;
  })
);

export const ResetEnv = ({ reload = false }) => {
  const handleEnvReset = async () => {
    localStorage.removeItem('NRL_PARTS_PATH');
    localStorage.removeItem('NRL_USER_MANAGEMENT_PATH');
    localStorage.removeItem('NRL_BASE_URL');
    localStorage.removeItem('userToken');

    const { status } = await getBasicAuthRefreshApi()
      .then((api) => api)
      .createRefreshTokenRevocation()
      .catch((e) => e);
    if (status) {
      localStorage.removeItem('userToken');
    }

    if (reload) {
      window.location.reload();
    }
  };
  return (
    <Button onClick={handleEnvReset} color="info" variant="contained" size="small">
      RESET TO DEFAULT
    </Button>
  );
};

const SHOW_SWITCH_BOOLEANS = [
  { name: 'Active Listing', value: 'show_activeListing' },
  { name: 'Listing State Icon', value: 'show_listingStateIcon' },
];

const initialBasePath = window.REACT_APP_NRL_BASE_URL;
const initialUmPath = window.REACT_APP_NRL_UM_URL.replace(/.*nrail.de/, '');
const initialPartsPath = window.REACT_APP_NRL_PARTS_URL.replace(/.*nrail.de/, '');

const DevSettings = () => {
  const theme = useTheme();

  const [NRL_BASE_URL, setNrlBaseUrl] = useState(initialBasePath);
  const [NRL_PARTS_PATH, setNrlPathParts] = useState(initialPartsPath);
  const [NRL_USER_MANAGEMENT_PATH, setNrlUserManagementPath] = useState(initialUmPath);
  const [featureState, setFeatureState] = React.useState(getDevSettings('show_', false));

  const handleFeatureStateChange = (event) => {
    setFeatureState({
      ...featureState,
      [event.target.value]: !featureState[event.target.value],
    });

    if (event.target.checked) {
      localStorage.setItem(event.target.value, event.target.checked);
    } else {
      localStorage.removeItem(event.target.value);
    }
  };

  const handleConfigSave = async () => {
    const items = {
      NRL_BASE_URL,
      NRL_PARTS_PATH,
      NRL_USER_MANAGEMENT_PATH,
    };

    if (NRL_USER_MANAGEMENT_PATH !== window.REACT_APP_INITIAL_ENV) {
      localStorage.removeItem('userToken');
    }

    Object.keys(items).forEach((key) => {
      localStorage.setItem(key, items[key]);
    });

    setTimeout(() => window.location.reload(), 500);
  };
  return (
    <>
      <Helmet>
        <title>DEV SETTINGS</title>
      </Helmet>
      <Box sx={{ p: 3, code: { color: theme.palette.info.main } }}>
        <Grid container spacing={2} justifyContent="center">
          <Grid item xs={12}>
            <Typography textAlign="center" variant="h1">
              Parts Configs
            </Typography>
          </Grid>
          <Grid item container spacing={2}>
            <Grid item xs={12}>
              <Typography variant="h6">
                NRL_BASE_URL env default: <code color="red">{window.REACT_APP_INITIAL_ENV}</code>
                <Typography variant="caption">
                  (changing the base url will revoke the current refresh token and prompt a new login for that system)
                </Typography>
              </Typography>
            </Grid>
            <Grid item xs={4}>
              <TextField label="Current" disabled fullWidth value={initialBasePath} />
            </Grid>
            <Grid item xs={1} sx={{ button: { mr: 2, ml: 2 } }}>
              <Button size="small" variant="contained" onClick={() => setNrlBaseUrl(initialBasePath)}>
                {'copy ==>'}
              </Button>
            </Grid>
            <Grid item xs={4}>
              <TextField
                label="New"
                fullWidth
                onChange={({ target }) => setNrlBaseUrl(target.value)}
                value={NRL_BASE_URL}
              />
            </Grid>
            <Grid display="flex" alignItems="center" sx={{ button: { mr: 2 } }} item xs={2}>
              <Button
                size="small"
                variant="contained"
                onClick={() => {
                  localStorage.setItem('NRL_BASE_URL', NRL_BASE_URL?.toLocaleLowerCase());
                }}
              >
                Save
              </Button>
              <Button
                size="small"
                color="info"
                variant="contained"
                onClick={() => {
                  setNrlBaseUrl(window.REACT_APP_INITIAL_ENV);
                  localStorage.removeItem('NRL_BASE_URL');
                }}
              >
                Default
              </Button>
            </Grid>
          </Grid>

          <Grid item container spacing={2}>
            <Grid item xs={12}>
              <Typography variant="h6">
                NRL_USER_MANAGEMENT_PATH. env default: <code>{process.env.REACT_APP_NRL_USER_MANAGEMENT_PATH}</code>
              </Typography>
            </Grid>
            <Grid item xs={4}>
              <TextField label="Current" disabled fullWidth value={initialUmPath} />
            </Grid>
            <Grid item xs={1} sx={{ button: { mr: 2, ml: 2 } }}>
              <Button size="small" variant="contained" onClick={() => setNrlUserManagementPath(initialUmPath)}>
                {'copy ==>'}
              </Button>
            </Grid>
            <Grid item xs={4}>
              <TextField
                label="New"
                fullWidth
                onChange={({ target }) => setNrlUserManagementPath(target.value)}
                value={NRL_USER_MANAGEMENT_PATH}
              />
            </Grid>
            <Grid display="flex" alignItems="center" item xs={2} sx={{ button: { mr: 2 } }}>
              <Button
                size="small"
                variant="contained"
                onClick={() => {
                  localStorage.setItem('NRL_USER_MANAGEMENT_PATH', NRL_USER_MANAGEMENT_PATH?.toLocaleLowerCase());
                }}
              >
                Save
              </Button>
              <Button
                size="small"
                color="info"
                variant="contained"
                onClick={() => {
                  setNrlUserManagementPath(process.env.REACT_APP_NRL_USER_MANAGEMENT_PATH);
                  localStorage.removeItem('NRL_USER_MANAGEMENT_PATH');
                }}
              >
                default
              </Button>
            </Grid>
          </Grid>

          <Grid item container spacing={2}>
            <Grid item xs={12}>
              <Typography variant="h6">
                NRL_PARTS_PATH env default: <code>{process.env.REACT_APP_NRL_PARTS_PATH}</code>
              </Typography>
            </Grid>
            <Grid item xs={4}>
              <TextField label="Current" disabled fullWidth value={initialPartsPath} />
            </Grid>
            <Grid item xs={1} sx={{ button: { mr: 2, ml: 2 } }}>
              <Button size="small" variant="contained" onClick={() => setNrlPathParts(initialPartsPath)}>
                {'copy ==>'}
              </Button>
            </Grid>
            <Grid item xs={4}>
              <TextField
                label="New"
                fullWidth
                onChange={({ target }) => setNrlPathParts(target.value)}
                value={NRL_PARTS_PATH}
              />
            </Grid>
            <Grid display="flex" alignItems="center" sx={{ button: { mr: 2 } }} item xs={2}>
              <Button
                size="small"
                variant="contained"
                onClick={() => {
                  localStorage.setItem('NRL_PARTS_PATH', NRL_PARTS_PATH?.toLocaleLowerCase());
                }}
              >
                Save
              </Button>
              <Button
                size="small"
                color="info"
                variant="contained"
                onClick={() => {
                  setNrlPathParts(process.env.REACT_APP_NRL_PARTS_PATH);
                  localStorage.removeItem('NRL_PARTS_PATH');
                }}
              >
                default
              </Button>
            </Grid>
          </Grid>

          <Grid
            display="flex"
            minWidth="100vw"
            justifyContent="center"
            alignItems="center"
            item
            sx={{ button: { marginRight: 3 } }}
          >
            <Button variant="contained" onClick={handleConfigSave}>
              Save All
            </Button>
            <Button
              color={'info'}
              variant="contained"
              onClick={() => {
                window.open('/');
                setTimeout(() => (window.location = window.location), 500);
              }}
            >
              Login
            </Button>
          </Grid>
        </Grid>

        <Grid container flexDirection="column">
          <Grid item>
            <FormControl component="fieldset" variant="standard">
              <FormLabel component="legend">Show</FormLabel>
              <FormGroup>
                {SHOW_SWITCH_BOOLEANS.map(({ name, value }) => (
                  <FormControlLabel
                    key={value}
                    control={<Switch onChange={handleFeatureStateChange} />}
                    label={name}
                    value={value}
                    checked={featureState[value]}
                  />
                ))}
              </FormGroup>
            </FormControl>
          </Grid>

          <Grid item hidden>
            <Button
              onClick={() => {
                Object.keys(featureState).forEach((key) =>
                  featureState[key] ? localStorage.setItem(key, 'true') : localStorage.removeItem(key)
                );
              }}
            >
              Show Dev Features
            </Button>
          </Grid>
        </Grid>
      </Box>
    </>
  );
};

DevSettings.propTypes = {};

export default DevSettings;
