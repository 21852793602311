import { decode } from 'jwt-simple';
import { AuthPasskeyApi, Configuration, OrganisationsApi } from 'nrail-user-management-api-client';

import { store } from '../../../index';
import { cleanUpLocalStorage } from '../../../utils/helperUtilities';
import { setAccessToken } from '../../../views/authenticationViews/authenticationSlice';
// eslint-disable-next-line import/no-cycle
import {
  basePath,
  configureRequestHeaders,
  getAccountsApi,
  getBasicAuthRefreshApi,
  getPassKeyApi,
  getRightsObj,
  getRolesApi,
} from './index';

/** *
 * Login Function
 * @param accountName
 * @param passkey
 *
 */
export const login = async (accountName, passkey, errorCallback) => {
  // let resetPass = false;
  const { data, status, message } = await getBasicAuthRefreshApi()
    .createRefreshToken({
      accountName,
      passkey,
    })
    .catch((e) => {
      console.dir(e);
      if (e?.response?.status === 401) {
        // resetPass = true;
        errorCallback(true);
      }
      return { message: e?.message, ...e.response };
    });

  if (data?.refreshToken) {
    await configureRequestHeaders(data?.refreshToken, undefined, true).then((resp) => resp);
  }

  return { data, status, message };
};

export const logout = async () => {
  const { status } = await getBasicAuthRefreshApi()
    .createRefreshTokenRevocation()
    .catch((e) => e);
  if (status === 204) {
    // cleanUpLocalStorage();
  }

  store?.dispatch(setAccessToken(null));
  cleanUpLocalStorage(); // be removed later
  sessionStorage.clear();
  return status;
};

// export const newPasswordRequest = async (accountName) =>
//   (await getPassKeyApi())
//     .createPasskeyResetRequest({ accountName })
//     .then((resp) => resp.status)
//     .catch((e) => e.response.status);
export const newPasswordRequestWithOrigin = async (accountName, origin, emailLocale = 'en') =>
  new AuthPasskeyApi(new Configuration({ basePath }))
    .createPasskeyResetRequest({ accountName, origin, emailLocale })
    .then((resp) => resp.status)
    .catch((e) => console.dir(e) || e.response.status);

export const updateUserPassword = async (newPasskey, oldPasskey, accessToken) =>
  new AuthPasskeyApi(new Configuration({ basePath, accessToken }))
    .updatePasskey({ newPasskey, oldPasskey })
    .then((resp) => resp.status)
    .catch((e) => console.dir(e) || e.response.status);

export const getOrganisations = async (accessToken) =>
  new OrganisationsApi(new Configuration({ basePath, accessToken }))
    .getOrganisations()
    .then((resp) => resp)
    .catch((e) => console.dir(e) || e.response.status);

export const resetPassword = async (newPasskey, resetAuthentificationToken) =>
  new AuthPasskeyApi(new Configuration({ basePath }))
    .createPasskeyReset({ newPasskey, resetAuthentificationToken })
    .then((resp) => resp.status)
    .catch((e) => {
      console.dir(e);
      return e.response.status;
    });

export const getRightsObjects = async () => {
  const { data } = await getRightsObj().then((api) => api.getRightsObjects());
  return data;
};

export const getRoles = async (queryParams = { orderby: ['roleName'] }) => {
  const { search, top, skip, filter, count, orderby, select } = queryParams;
  const { data } = await getRolesApi().then((api) =>
    api.getRoles(search, top, skip, filter, count, orderby, select).catch((e) => e)
  );

  return data;
};

export const getRoleAccessRights = async (roleId) => {
  const { data } = await getRolesApi().then((api) =>
    api.getRoleAccessRights(roleId).catch((e) => ({
      data: { message: `${e}` },
    }))
  );

  return data;
};

export const getAccountAccessRights = async () => {
  const refreshToken = localStorage.getItem('userToken');
  const { accountId } = decode(refreshToken, '', true);
  const resp = await getAccountsApi().then((api) =>
    api
      .getAccountAccessRights(accountId)
      .then(({ data }) => {
        const tempRightsObj = {};
        data?.accessRights.forEach((rightsOrg) => {
          if (rightsOrg?.issuingOrganisationId) {
            tempRightsObj[rightsOrg.issuingOrganisationId] = {};
            rightsOrg.accessRights.forEach((rights) => {
              tempRightsObj[rightsOrg.issuingOrganisationId][rights.rightsObjectName] = rights;
              // activeOrganisationId
            });
          }
        });
        return { data, rightsObj: tempRightsObj };
      })
      .catch((e) => ({
        data: { message: `${e}` },
      }))
  );

  return resp.rightsObj;
};
//
//
// export const getAccountAccessRights = async () => {
//   const refreshToken = localStorage.getItem('userToken');
//   const { accountId } = decode(refreshToken, '', true);
//   const { data } = await accountsApi.getAccountAccessRights(accountId).catch(e => ({
//     data: { message: e + '' },
//   }));
//   return data;
// };
//
//
// export const getPersonAccount = async () => {
//   const refreshToken = localStorage.getItem('userToken');
//   const { accountId } = decode(refreshToken, '', true);
//   const { data } = await accPersonApi.getPersonAccount(accountId).catch(e => ({
//     data: { message: e + '' },
//   }));
//   return data;
// };
//
// export const getPersonAccountMemberships = async () => {
//   const refreshToken = localStorage.getItem('userToken');
//   const { accountId } = decode(refreshToken, '', true);
//   const { data } = await accPersonMembershipApi.getPersonAccountMemberships(accountId).catch(e => ({
//     data: { message: e + '' },
//   }));
//   return data;
// };
// {
//   "safety_deadman_circuit": "safety_deadman_circuit"
//   "power_control": "power_control",
//   "misc_electronic_systems": "misc_electronic_systems",
//
// }
