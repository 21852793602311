import * as PartsApi from 'nrail-parts-api-client';

import { configureRequestHeaders } from '../userManagement';

const basePath = window?.REACT_APP_NRL_PARTS_URL || '';

let partsConfig = new PartsApi.Configuration({ basePath });

export const updateConfigs = async (accessToken) => {
  partsConfig = new PartsApi.Configuration({ basePath, accessToken });
};
// let partsListingApi = new MarketplaceListingsApi(partsConfig);

let apiCache = {
  currentToken: '',
};

const apiTypeMap = {
  partsListingApi: PartsApi.MarketplaceListingsApi,
  selectListApi: PartsApi.SelectListsApi,
  organisationsListingsApi: PartsApi.OrganisationsListingsApi,
  organisationsListingsJobsApi: PartsApi.OrganisationsListingsJobsApi,
  organisationsImagesApi: PartsApi.OrganisationsImagesApi,
  organisationsPdfApi: PartsApi.OrganisationsPdfApi,
  advertisingApi: PartsApi.AdvertisingApi,
  organisationsReservationsApi: PartsApi.OrganisationsListingsReservationsApi,
  organisationsListingsVehiclesApi: PartsApi.OrganisationsListingsVehiclesApi,
  organisationsSettingsApi: PartsApi.OrganisationsSettingsApi,
  administrationListingsApi: PartsApi.AdministrationListingsApi,
  organisationsListingsServicesApi: PartsApi.OrganisationsListingsServicesApi,
  // Vehicle
  vehiclesVehicleRequestsApi: PartsApi.VehiclesVehicleRequestsApi,
  vehiclesVehicleFinancingApi: PartsApi.VehiclesVehicleFinancingApi,
  marketplaceListingsVehiclesApi: PartsApi.MarketplaceListingsVehiclesApi,
  //Jobs
  marketplaceListingsJobsApi: PartsApi.MarketplaceListingsJobsApi,
  marketplaceListingsServicesApi: PartsApi.MarketplaceListingsServicesApi,
  // Parts request
  marketplacePartsRequestsApi: PartsApi.MarketplacePartsRequestsApi,
  organisationsPartsRequestsApi: PartsApi.OrganisationsPartsRequestsApi,
  administrationPartsRequestsApi: PartsApi.AdministrationPartsRequestsApi,
  partsSettingsApi: PartsApi.PartsSettingsApi,

  // orders
  organisationsAddressesAPi: PartsApi.OrganisationsAddressesApi,
  ordersApi: PartsApi.OrdersApi,
  vehiclesApi: PartsApi.VehiclesApi,
};

// PartsApi.OrganisationsListingsApi().then((api) => api.get);

const getApi = async (apiName) => {
  const currentToken = await configureRequestHeaders();

  if (apiCache.currentToken === '' || apiCache.currentToken !== currentToken) {
    await updateConfigs(currentToken);
    apiCache = { currentToken };
  }

  if (apiCache[apiName]) {
    return apiCache[apiName];
  }

  apiCache[apiName] = await new apiTypeMap[apiName](partsConfig);

  return apiCache[apiName];
};

const partsListingApi = async () => getApi('partsListingApi');
const marketplaceListingsJobsApi = async () => getApi('marketplaceListingsJobsApi');
const marketplaceListingsServicesApi = async () => getApi('marketplaceListingsServicesApi');

const selectListApi = async () => getApi('selectListApi');
const organisationsListingsApi = async () => getApi('organisationsListingsApi');
const organisationsListingsJobsApi = async () => getApi('organisationsListingsJobsApi');
const organisationsListingsServicesApi = async () => getApi('organisationsListingsServicesApi');
const AdvertisingApi = async () => getApi('advertisingApi');
const organisationsImagesApi = async () => getApi('organisationsImagesApi');
const organisationsPdfApi = async () => getApi('organisationsPdfApi');
const organisationsReservationsApi = async () => getApi('organisationsReservationsApi');
const organisationsSettingsApi = async () => getApi('organisationsSettingsApi');
const organisationsListingsVehiclesApi = async () => getApi('organisationsListingsVehiclesApi');
const administrationListingsApi = async () => getApi('administrationListingsApi');
const globalSettingsApi = async () => getApi('partsSettingsApi');

// Parts request
const vehiclesVehicleRequestsApi = async () => getApi('vehiclesVehicleRequestsApi');
const vehiclesVehicleFinancingApi = async () => getApi('vehiclesVehicleFinancingApi');
const marketplaceListingsVehiclesApi = async () => getApi('marketplaceListingsVehiclesApi');
const marketplacePartsRequestsApi = async () => getApi('marketplacePartsRequestsApi');
const organisationsPartsRequestsApi = async () => getApi('organisationsPartsRequestsApi');
const administrationPartsRequestsApi = async () => getApi('administrationPartsRequestsApi');

// Orders
const organisationsAddressesAPi = async () => getApi('organisationsAddressesAPi');
const ordersApi = async () => getApi('ordersApi');
const vehiclesApi = async () => getApi('vehiclesApi');

export const getPartsListingsBanners = async (
  top = undefined,
  filter = undefined,
  requestID = undefined,
  options = {}
) =>
  AdvertisingApi()
    .then((api) => api.getAdBanners(top, filter, requestID, options))
    .then(({ data }) => data);

export const getPartsListings = async (
  search = undefined,
  top = undefined,
  skip = undefined,
  filter = undefined,
  count = undefined,
  orderby = ['creationTimestamp desc'],
  organisationSelect = undefined,
  requestID = undefined,
  options = {}
) =>
  partsListingApi()
    .then((api) =>
      api.getMarketplaceListings(
        search,
        top,
        skip,
        filter,
        count,
        orderby,
        organisationSelect,
        requestID,
        options
      )
    )
    .then(({ data }) => data);

export const getPartsServices = async (
  search = undefined,
  top = undefined,
  skip = undefined,
  filter = undefined,
  count = undefined,
  orderby = ['creationTimestamp desc'],
  requestID = undefined,
  options = {}
) =>
  marketplaceListingsServicesApi()
    .then((api) =>
      api.getMarketplaceServiceListings(
        search,
        top,
        skip,
        filter,
        count,
        orderby,
        requestID,
        options
      )
    )
    .then(({ data }) => data);

export const getJobsPosting = async (
  search = undefined,
  top = undefined,
  skip = undefined,
  filter = undefined,
  count = undefined,
  orderby = ['creationTimestamp desc'],
  requestID = undefined,
  options = {}
) =>
  marketplaceListingsJobsApi()
    .then((api) =>
      api.getMarketplaceJobListings(
        search,
        top,
        skip,
        // filter,
        count,
        orderby,
        requestID,
        options
      )
    )
    .then(({ data }) => data);

export const getMarketPlaceSparepartsMenu = async ({ requestID = undefined, options = {} }) =>
  selectListApi()
    .then((api) => api.getSparePartsListingCategories(requestID, options))
    .catch();

export const getMarketPlaceWorkshopEquipmentsMenu = async ({
  requestID = undefined,
  options = {},
}) =>
  selectListApi()
    .then((api) => api.getWorkshopEquipmentListingCategories(requestID, options))
    .catch();

export const getMarketPlaceConsumableMenu = async ({ requestID = undefined, options = {} }) =>
  selectListApi()
    .then((api) => api.getConsumablesListingCategories(requestID, options))
    .catch();

export const getMarketPlaceServicesMenu = async ({ requestID = undefined, options = {} }) =>
  selectListApi()
    .then((api) => api.getServiceListingCategories(requestID, options))
    .catch();

export const getPartsListingById = async (
  listingId,
  organisationSelect = undefined,
  requestID = undefined,
  options = {}
) =>
  partsListingApi()
    .then((api) => api.getMarketplaceListing(listingId, organisationSelect, requestID, options))
    .catch();

export const getOrganisationListingImagesByListingId = async (
  listingId,
  organisationSelect = undefined,
  requestID = undefined
) =>
  organisationsListingsApi()
    .then((api) =>
      api.getOrganisationListingImagesByListingId(listingId, organisationSelect, requestID)
    )
    .catch();

export const createListingRequest = async (listingId, reservationPurchaseDetails) =>
  partsListingApi().then((api) => api.createListingRequest(listingId, reservationPurchaseDetails));

export const getJobsFilters = async ({
  search = undefined,
  filter = undefined,
  usageContext = undefined,
  organisationSelect = undefined,
  language = 'de-DE',
}) =>
  selectListApi()
    .then((api) => api.getJobFilters(search, filter, usageContext, organisationSelect, language))
    .catch();

export const getFilters = async ({
  search = undefined,
  filter = undefined,
  usageContext = undefined,
  organisationSelect = undefined,
  language = 'de-DE',
  options = undefined,
}) =>
  selectListApi()
    .then((api) =>
      api.getListingFilters(search, filter, usageContext, organisationSelect, language, options)
    )
    .catch();

// Org listings
export const getSparePartsCategoryList = async ({
  search = undefined,
  filter = undefined,
  usageContext = undefined,
  organisationSelect = undefined,
}) =>
  selectListApi().then((api) =>
    api.getSparePartsCategoryList(search, filter, usageContext, organisationSelect)
  );
export const getAssemblySelectList = async ({
  search = undefined,
  filter = undefined,
  usageContext = undefined,
  organisationSelect = undefined,
}) =>
  selectListApi().then((api) =>
    api.getAssemblies(search, filter, usageContext, organisationSelect)
  );

export const getWorkshopSelectList = async (filter = undefined) =>
  selectListApi().then((api) => api.getCategoryList(filter));

export const getFullCategoryList = async (filter = undefined, usageContext = undefined) =>
  selectListApi().then((api) => api.getFullCategoryList(filter, usageContext));

export const getConsumablesSelectList = async ({
  search = undefined,
  filter = undefined,
  usageContext = undefined,
  organisationSelect = undefined,
}) =>
  selectListApi().then((api) =>
    api.getConsumableCategoryList(search, filter, usageContext, organisationSelect)
  );

export const getConditionsList = async ({
  search = undefined,
  filter = undefined,
  usageContext = undefined,
  organisationSelect = undefined,
}) =>
  selectListApi().then((api) =>
    api.getConditions(search, filter, usageContext, organisationSelect)
  );

export const getAssembliesForPartsRequests = async ({
  ifNoneMatch = undefined,
  search = undefined,
  filter = undefined,
  usageContext = undefined,
  organisationSelect = undefined,
}) =>
  selectListApi().then((api) =>
    api.getAssembliesForPartsRequests(ifNoneMatch, search, filter, usageContext, organisationSelect)
  );

export const getConditionsForPartsRequests = async ({
  ifNoneMatch = undefined,
  search = undefined,
  filter = undefined,
  usageContext = undefined,
  organisationSelect = undefined,
}) =>
  selectListApi().then((api) =>
    api.getConditionsForPartsRequests(ifNoneMatch, search, filter, usageContext, organisationSelect)
  );

export const getManufacturersSelectList = async ({
  ifNoneMatch = undefined,
  search = undefined,
  filter = undefined,
  usageContext = undefined,
  organisationSelect = undefined,
  language = 'de-DE',
}) =>
  selectListApi().then((api) =>
    api.getManufacturers(ifNoneMatch, search, filter, usageContext, organisationSelect, language)
  );

export const getManufacturersForPartsRequests = async ({
  ifNoneMatch = undefined,
  search = undefined,
  filter = undefined,
  usageContext = undefined,
  organisationSelect = undefined,
}) =>
  selectListApi().then((api) =>
    api.getManufacturersForPartsRequests(
      ifNoneMatch,
      search,
      filter,
      usageContext,
      organisationSelect
    )
  );

export const getOrganisationsSelectList = async ({ search = undefined, filter = undefined }) =>
  selectListApi().then((api) => api.getOrganisations(search, filter));

export const getOrganisationsForPartsRequests = async ({
  ifNoneMatch = undefined,
  search = undefined,
  filter = undefined,
  usageContext = undefined,
}) =>
  selectListApi().then((api) =>
    api.getOrganisationsForPartsRequests(ifNoneMatch, search, filter, usageContext)
  );

export const getStates = async ({
  usageContext = 'organisation',
  search = undefined,
  filter = undefined,
  organisationSelect = undefined,
}) =>
  selectListApi().then((api) => api.getStates(usageContext, search, filter, organisationSelect));

export const getStatesForPartsRequests = async ({
  ifNoneMatch = undefined,
  usageContext = 'organisation',
  search = undefined,
  filter = undefined,
  organisationSelect = undefined,
}) =>
  selectListApi().then((api) =>
    api.getStatesForPartsRequests(ifNoneMatch, search, filter, usageContext, organisationSelect)
  );

export const getReservationCountBounds = async ({
  usageContext = 'organisation',
  search = undefined,
  filter = undefined,
  organisationSelect = undefined,
}) =>
  selectListApi().then((api) =>
    api.getReservationCountBounds(usageContext, search, filter, organisationSelect)
  );

export const getConditionFilter = async ({
  usageContext = 'organisation',
  search = undefined,
  filter = undefined,
  organisationSelect = undefined,
}) =>
  selectListApi().then((api) =>
    api.getConditions(search, filter, usageContext, organisationSelect)
  );

export const getConsumablesFilter = async ({
  usageContext = 'organisation',
  search = undefined,
  filter = undefined,
  organisationSelect = undefined,
}) =>
  selectListApi().then((api) =>
    api.getConsumables(search, filter, usageContext, organisationSelect)
  );

export const getAllowDirectBuy = async ({
  usageContext = 'organisation',
  search = undefined,
  filter = undefined,
  organisationSelect = undefined,
}) =>
  selectListApi().then((api) => api.getDirectBuy(search, filter, usageContext, organisationSelect));

export const getVehicleTypesSelectList = async ({
  ifNoneMatch = undefined,
  search = undefined,
  filter = undefined,
  usageContext = undefined,
  organisationSelect = undefined,
}) =>
  selectListApi().then((api) =>
    api.getVehicleTypes(ifNoneMatch, search, filter, usageContext, organisationSelect)
  );

export const getVehicleTypesForPartsRequests = async ({
  ifNoneMatch = undefined,
  search = undefined,
  filter = undefined,
  usageContext = undefined,
  organisationSelect = undefined,
}) =>
  selectListApi().then((api) =>
    api.getVehicleTypesForPartsRequests(
      ifNoneMatch,
      search,
      filter,
      usageContext,
      organisationSelect
    )
  );

// export const getAssemblySelectList = async () => selectListApi.get();
export const updatePartsListing = async (
  organisationId,
  listingId,
  listingParameter,
  requestID = undefined
) =>
  organisationsListingsApi().then((api) =>
    api.updateOrganisationListing(organisationId, listingId, listingParameter, requestID)
  );
export const deleteOrganisationListing = async (organisationId, listingId, requestID = undefined) =>
  organisationsListingsApi().then((api) =>
    api.deleteOrganisationListing(organisationId, listingId, requestID)
  );

export const deleteAdsBanningListing = async (organisationId, listingId, requestID = undefined) =>
  AdvertisingApi().then((api) => api.deleteAdBannerListing(organisationId, listingId, requestID));

export const deleteOrganisationJob = async (listingId, organisationId, requestID = undefined) =>
  organisationsListingsJobsApi().then((api) =>
    api.deleteOrganisationJobListing(listingId, organisationId, requestID)
  );

export const deleteOrganisationVehicleListing = async (
  listingId,
  organisationId,
  requestID = undefined
) =>
  organisationsListingsVehiclesApi().then((api) =>
    api.deleteOrganisationVehicleListing(listingId, organisationId, requestID)
  );

export const deleteOrganisationServiceListing = async (
  listingId,
  organisationId,
  requestID = undefined
) =>
  organisationsListingsServicesApi().then((api) =>
    api.deleteOrganisationServiceListing(listingId, organisationId, requestID)
  );

export const createOrganisationListing = async (
  organisationId,
  listingId,
  listingParameter,
  requestID = undefined
) =>
  organisationsListingsApi().then((api) =>
    api.createOrganisationListing(organisationId, listingId, listingParameter, requestID)
  );
// organisationPartsRequestCreateBody

export const createOrganisationJob = async (organisationId, listingParameter, requestID) =>
  organisationsListingsJobsApi().then((api) =>
    api.createOrganisationJobListing(organisationId, listingParameter, requestID)
  );

export const createOrganisationListingVehicles = async (
  organisationId,
  listingParameter,
  requestID
) =>
  organisationsListingsVehiclesApi().then((api) =>
    api.createOrganisationVehiclesListing(organisationId, listingParameter, requestID)
  );

export const createOrganisationBannerListing = async (
  organisationId,
  listingParameter,
  requestID
) =>
  AdvertisingApi().then((api) =>
    api.createAdBannerListing(organisationId, listingParameter, requestID)
  );

export const createOrganisationServicesListing = async (
  organisationId,
  listingParameter,
  requestID
) =>
  organisationsListingsServicesApi().then((api) =>
    api.createOrganisationServicesListing(organisationId, listingParameter, requestID)
  );

export const createOrganisationBanner = async (organisationId, listingParameter, requestID) =>
  AdvertisingApi().then((api) =>
    api.createOrganisationBanner(organisationId, listingParameter, requestID)
  );

export const updateOrganisationBanner = async (listingId, organisationId, listingParameter) =>
  AdvertisingApi().then((api) =>
    api.updateAdBannerListing(organisationId, listingId, listingParameter)
  );

export const updateOrganisationJob = async (listingId, organisationId, listingParameter) =>
  organisationsListingsJobsApi().then((api) =>
    api.updateOrganisationJobListing(listingId, organisationId, listingParameter)
  );

export const updateOrganisationListingVehicles = async (
  listingId,
  organisationId,
  listingParameter
) =>
  organisationsListingsVehiclesApi().then((api) =>
    api.updateOrganisationVehiclesListing(listingId, organisationId, listingParameter)
  );

export const updateOrganisationListingServices = async (
  listingId,
  organisationId,
  listingParameter
) =>
  organisationsListingsServicesApi().then((api) =>
    api.updateOrganisationServicesListing(listingId, organisationId, listingParameter)
  );

export const getOrganisationListings = async (
  organisationId,
  search = undefined,
  top = undefined,
  skip = undefined,
  filter = undefined,
  count = undefined,
  orderby = ['creationTimestamp desc'],
  requestID = undefined
) =>
  organisationsListingsApi().then((api) =>
    api
      .getOrganisationListings(organisationId, search, top, skip, filter, count, orderby, requestID)
      .then(({ data }) => data)
  );

export const downloadList = async (organisationId, fileType) =>
  organisationsListingsApi().then((api) =>
    api.getOrganisationListingExportLink(organisationId, fileType).then((data) => data)
  );

export const getOrganisationListingReservations = async (listingId, organisationId) =>
  organisationsReservationsApi().then((api) =>
    api.getOrganisationListingReservations(listingId, organisationId)
  );

export const getOrganisationSettings = async (organisationId) =>
  organisationsSettingsApi().then((api) => api.getOrganisationSettings(organisationId));

export const updateOrganisationSettings = async (organisationId, newValue) =>
  organisationsSettingsApi().then((api) =>
    api.updateOrganisationSettings(organisationId, newValue)
  );

export const createOrganisationListingReservation = async (
  listingId,
  organisationId,
  reservationCreate
) =>
  organisationsReservationsApi().then((api) =>
    api.createOrganisationListingReservation(listingId, organisationId, reservationCreate)
  );
export const deleteOrganisationListingReservation = async (
  organisationId,
  listingId,
  reservationId
) =>
  organisationsReservationsApi().then((api) =>
    api.deleteOrganisationListingReservation(organisationId, listingId, reservationId)
  );
export const updateOrganisationListingReservation = async (
  listingId,
  organisationId,
  reservationId,
  reservationCreate
) =>
  organisationsReservationsApi().then((api) =>
    api.updateOrganisationListingReservation(
      organisationId,
      listingId,
      reservationId,
      reservationCreate
    )
  );

export const getOrganisationListingReservationById = async (
  organisationId,
  listingId,
  reservationId
) =>
  organisationsReservationsApi().then((api) =>
    api.getOrganisationListingReservation(organisationId, listingId, reservationId)
  );

export const getAdministrativeListings = async (
  organisationId,
  search = undefined,
  top = undefined,
  skip = undefined,
  filter = undefined,
  count = undefined,
  orderby = ['creationTimestamp desc'],
  requestID = undefined
) =>
  administrationListingsApi().then((api) =>
    api
      .getAdministrativeListings(search, top, skip, filter, count, orderby, requestID)
      .then(({ data }) => data)
  );

export const getOrganisationListingById = async (
  organisationId,
  listingId,
  requestID = undefined
) =>
  organisationsListingsApi()
    .then((api) => api.getOrganisationListing(organisationId, listingId, requestID))
    .then(({ data }) => data || {});

export const getAdministrativeListingById = async (
  organisationSelect = undefined,
  listingId,
  requestID = undefined
) =>
  administrationListingsApi()
    .then((api) =>
      api.getAdministrativeListings(
        undefined,
        undefined,
        undefined,
        `listingId eq ${listingId}`,
        undefined,
        undefined,
        requestID
      )
    )
    .then(({ data }) => data.items[0] || {});

export const updateOrganisationListingReviewState = async (organisationId, listingId, state) =>
  organisationsListingsApi().then((api) =>
    api.updateOrganisationListingReviewState(organisationId, listingId, { state })
  );

export const updateOrganisationListingState = async (organisationId, listingId, stateChange) =>
  organisationsListingsApi().then((api) =>
    api.updateOrganisationListingState(organisationId, listingId, stateChange)
  );

export const processImage = async (organisationId, imageTarget, imageBase64) =>
  organisationsImagesApi().then((api) =>
    api.processImages(organisationId, imageTarget, imageBase64)
  );

export const addImageToOrganisationLibrary = async (
  organisationId,
  imageTarget,
  imageBase64,
  keepImage = false,
  removeBg = false
) =>
  organisationsImagesApi().then((api) =>
    api.createUploadImage(organisationId, imageTarget, { imageBase64 }, keepImage, removeBg)
  );

export const addPDFToOrganisationLibrary = async (
  organisationId,
  listingId,
  pdfTarget,
  pdfBase64
) =>
  organisationsPdfApi().then((api) =>
    api.createUploadPDF(organisationId, pdfTarget, { listingId, pdfBase64 })
  );

export const getGlobalSettings = async () =>
  globalSettingsApi().then((api) => api.getGlobalSettings());

/**
 * Parts Request: Marketplace
 * */

export const getMarketplacePartsRequests = async (
  search = undefined,
  top = undefined,
  skip = undefined,
  filter = undefined,
  count = undefined,
  organisationId = undefined,
  options = undefined
) =>
  marketplacePartsRequestsApi().then((api) =>
    api
      .getMarketplacePartsRequests(search, top, skip, filter, count, organisationId, options)
      .then(({ data }) => data)
  );
export const getMarketplacePartsRequest = async (
  partsRequestId = undefined,
  organisationSelect = undefined
) =>
  marketplacePartsRequestsApi().then((api) =>
    api.getMarketplacePartsRequest(partsRequestId, organisationSelect).then(({ data }) => data)
  );

export const createOfferForMarketplacePartsRequest = async (
  partsRequestId = undefined,
  forOrganisationId,
  requestID,
  MarketplacePartsRequestOfferCreate
) =>
  marketplacePartsRequestsApi().then((api) =>
    api.createOfferForMarketplacePartsRequest(
      partsRequestId,
      forOrganisationId,
      requestID,
      MarketplacePartsRequestOfferCreate
    )
  );

export const createInquiryForMarketplacePartsRequest = async (
  partsRequestId = undefined,
  forOrganisationId,
  marketplacePartsRequestInquiryCreate
) =>
  marketplacePartsRequestsApi().then((api) =>
    api.createInquiryForMarketplacePartsRequest(
      partsRequestId,
      forOrganisationId,
      marketplacePartsRequestInquiryCreate
    )
  );

/**
 * Vehicle Request: Vehicles
 * */
export const createInquiryForVehicleRequest = async (requestID, vehiclesRequestInquiryCreate) =>
  vehiclesApi().then((api) =>
    api.createVehicleExposeRequest(requestID, vehiclesRequestInquiryCreate, {})
  );

export const createrVehicleFinancing = async (requestID, vehiclesRequestInquiryCreate) =>
  vehiclesApi().then((api) =>
    api.createVehicleFinancingRequest(requestID, vehiclesRequestInquiryCreate, {})
  );

export const getOrganisationVehicles = async (
  organisationId,
  search = undefined,
  top = undefined,
  skip = undefined,
  filter = undefined,
  count = undefined,
  orderby = ['creationTimestamp desc'],
  requestID = undefined
) =>
  organisationsListingsVehiclesApi().then((api) =>
    api
      .getOrganisationVehiclesListings(
        organisationId,
        search,
        top,
        skip,
        filter,
        count,
        orderby,
        requestID
      )
      .then(({ data }) => data)
  );

export const getOrganisationBanner = async (
  organisationId,
  search = undefined,
  top = undefined,
  skip = undefined,
  filter = undefined,
  count = undefined,
  orderby = ['creationTimestamp desc'],
  requestID = undefined
) =>
  AdvertisingApi().then((api) =>
    api
      .getAdBannerListings(organisationId, search, top, skip, filter, count, orderby, requestID)
      .then(({ data }) => data)
  );

export const getListingsPurchaseRequestsAdmin = async (
  top = undefined,
  skip,
  count = undefined,
  requestID
) =>
  administrationListingsApi().then((api) =>
    api
      .getAdministrationListingsPurchaseRequests(top, skip, count, requestID)
      .then(({ data }) => data)
  );

export const getJobListings = async (
  organisationId,
  search = undefined,
  top = undefined,
  skip,
  filter = undefined,
  count = undefined,
  orderby = ['creationTimestamp desc'],
  requestID,
  options = undefined
) =>
  organisationsListingsJobsApi().then((api) =>
    api
      .getOrganisationJobListings(organisationId, search, top, skip, count, orderby, options)
      .then(({ data }) => data)
  );

export const getListingsPurchaseRequests = async (
  organisationId,
  top = undefined,
  skip,
  count = undefined,
  requestID
) =>
  organisationsListingsApi().then((api) =>
    api
      .getListingsPurchaseRequests(organisationId, top, skip, count, requestID)
      .then(({ data }) => data)
  );

export const getServices = async (
  organisationId,
  search = undefined,
  top = undefined,
  skip = undefined,
  filter = undefined,
  count = undefined,
  orderby = ['creationTimestamp desc'],
  requestID = undefined
) =>
  organisationsListingsServicesApi().then((api) =>
    api
      .getOrganisationServicesListings(
        organisationId,
        search,
        top,
        skip,
        filter,
        count,
        orderby,
        requestID
      )
      .then(({ data }) => data)
  );

export const getVehicles = async (
  search = undefined,
  top = undefined,
  skip = undefined,
  filter = undefined,
  count = undefined,
  requestID = undefined,
  options = undefined
) =>
  marketplaceListingsVehiclesApi().then((api) =>
    api.getMarketplaceVehicleListings(search, top, skip, filter, count, requestID, options)
  );

export const getVehiclesById = async (
  listingId,
  organisationSelect = undefined,
  requestID,
  options = {}
) =>
  marketplaceListingsVehiclesApi()
    .then((api) =>
      api.getMarketplaceVehicleListing(listingId, organisationSelect, requestID, options)
    )
    .catch();

export const getJobById = async (
  listingId,
  organisationSelect = undefined,
  requestID,
  options = {}
) =>
  marketplaceListingsJobsApi()
    .then((api) => api.getMarketplaceJobListing(listingId, organisationSelect, requestID, options))
    .catch();
export const getServiceById = async (
  listingId,
  organisationSelect = undefined,
  requestID,
  options = {}
) =>
  marketplaceListingsServicesApi()
    .then((api) =>
      api.getMarketplaceServiceListing(listingId, organisationSelect, requestID, options)
    )
    .catch();

export const getOrganisationJobsById = async (
  listingId,
  organisationSelect = undefined,
  requestID = undefined,
  options = undefined
) =>
  organisationsListingsJobsApi()
    .then((api) => api.getOrganisationJobListing(listingId, organisationSelect, requestID, options))
    .catch();
export const getOrganisationVehiclesById = async (
  listingId,
  organisationSelect = undefined,
  requestID = undefined,
  options = undefined
) =>
  organisationsListingsVehiclesApi()
    .then((api) =>
      api.getOrganisationVehiclesListing(listingId, organisationSelect, requestID, options)
    )
    .catch();

export const sendVehiclesEmail = async (
  requestID = undefined,
  vehiclesRequestCreate = {},
  options = undefined
) =>
  vehiclesApi()
    .then((api) => api.createVehicleRequest(requestID, vehiclesRequestCreate, options))
    .catch();

export const getOrganisationBannersById = async (
  bannerId,
  organisationSelect = undefined,
  requestID = undefined,
  options = undefined
) =>
  AdvertisingApi()
    .then((api) => api.getAdBannerListing(organisationSelect, bannerId, requestID, options))
    .catch();

export const getAdCarousel = async (top, filter) =>
  AdvertisingApi()
    .then((api) => api.getAdCarousel(top, filter))
    .catch();

export const getOrganisationServicesById = async (
  listingId,
  organisationSelect = undefined,
  requestID = undefined,
  options = undefined
) =>
  organisationsListingsServicesApi()
    .then((api) =>
      api.getOrganisationServicesListing(listingId, organisationSelect, requestID, options)
    )
    .catch();

/**
 * Parts Request: OrganisationParts
 * */

export const createOrganisationPartsRequest = async (
  organisationId = undefined,
  organisationPartsRequestCreateBody
) =>
  organisationsPartsRequestsApi().then((api) =>
    api.createOrganisationPartsRequest(organisationId, organisationPartsRequestCreateBody)
  );

export const deletePartsRequest = async (organisationId = undefined, partsRequestId = undefined) =>
  organisationsPartsRequestsApi().then((api) =>
    api.deleteOrganisationPartsRequest(organisationId, partsRequestId)
  );

export const updateOrganisationPartsRequestState = async (
  organisationId = undefined,
  partsRequestId = undefined,
  state
) =>
  organisationsPartsRequestsApi().then((api) =>
    api.updateOrganisationPartsRequestState(organisationId, partsRequestId, state)
  );

export const getOrganisationPartsRequestState = async (
  organisationId = undefined,
  partsRequestId = undefined
) =>
  organisationsPartsRequestsApi().then((api) =>
    api.getOrganisationPartsRequestState(organisationId, partsRequestId)
  );

export const getOrganisationPartsRequests = async (
  organisationId = undefined,
  search = undefined,
  top = undefined,
  skip = undefined,
  filter = undefined,
  count = undefined,
  orderby = undefined,
  options = undefined
) =>
  organisationsPartsRequestsApi().then((api) =>
    api
      .getOrganisationPartsRequests(
        organisationId,
        search,
        top,
        skip,
        filter,
        count,
        orderby,
        options
      )
      .then(({ data }) => data)
  );
export const getAdministrationPartsRequests = async (
  organisationId = undefined,
  search = undefined,
  top = undefined,
  skip = undefined,
  filter = undefined,
  count = undefined,
  orderby = undefined,
  options = undefined
) =>
  administrationPartsRequestsApi().then((api) =>
    api
      .getAdministrationPartsRequests(search, top, skip, filter, count, orderby, options)
      .then(({ data }) => data)
  );

export const getOrganisationPartsRequestById = async (organisationId = undefined, partsRequestId) =>
  organisationsPartsRequestsApi().then((api) =>
    api.getOrganisationPartsRequest(organisationId, partsRequestId).then(({ data }) => data)
  );

export const getAdministrationPartsRequestById = async (
  organisationId = undefined,
  partsRequestId
) =>
  administrationPartsRequestsApi().then((api) =>
    api
      .getAdministrationPartsRequests(
        undefined,
        undefined,
        undefined,
        `partsRequestId eq ${partsRequestId}`
      )
      .then(({ data }) => data.items[0] || {})
  );

export const updateOrganisationPartsRequest = async (
  organisationId = undefined,
  partsRequestId = undefined,
  organisationPartsRequestUpdate
) =>
  organisationsPartsRequestsApi().then((api) =>
    api.updateOrganisationPartsRequest(
      organisationId,
      partsRequestId,
      organisationPartsRequestUpdate
    )
  );

export const updateOrganisationPartsRequestReviewState = async (
  organisationId = undefined,
  partsRequestId = undefined,
  partsRequestReviewStateChangeRequest
) =>
  administrationPartsRequestsApi().then((api) =>
    api.updateOrganisationPartsRequestReviewState(
      partsRequestId,
      organisationId,
      partsRequestReviewStateChangeRequest
    )
  );

export const getOrders = async (
  organisationId,
  search = undefined,
  top = undefined,
  skip = undefined,
  filter = undefined,
  count = undefined,
  orderby = ['creationTimestamp desc']
) =>
  ordersApi().then((api) =>
    api
      .getOrderListsOverview(organisationId, search, top, skip, filter, count, orderby)
      .then(({ data }) => data)
  );

export const getOrderById = async (orderId, organisationId) =>
  ordersApi().then((api) => api.getOrderList(orderId, organisationId).then(({ data }) => data));

export const checkoutOrderList = async (orderId, organisationId, address) =>
  ordersApi().then((api) =>
    api.checkoutOrderList(orderId, organisationId, address).then(({ data }) => data)
  );

export const updateOrderItem = async (organisationId, elementIdToBeUpdated, amountSelected) =>
  ordersApi().then((api) =>
    api
      .updateOrderList(organisationId, {
        elementIdToBeUpdated,
        amountSelected,
      })
      .then(({ data }) => data)
  );
export const deleteOrderItem = async (organisationId, elementIdToBeDropt) =>
  ordersApi().then((api) =>
    api
      .updateOrderList(organisationId, {
        elementIdToBeDropt,
      })
      .then(({ data }) => data)
  );

export const addOrderItem = async (organisationId, listingIdToBeAdd, amountSelected, requestID) =>
  ordersApi().then((api) =>
    api
      .updateOrderList(
        organisationId,
        {
          listingIdToBeAdd,
          amountSelected,
        },
        requestID
      )
      .then(({ data }) => data)
  );
export const updateOrderDeliveryAddress = async (organisationId, deliverAddressIdRef) =>
  ordersApi().then((api) =>
    api
      .updateOrderList(organisationId, {
        deliverAddressIdRef,
      })
      .then(({ data }) => data)
  );

export const getBasicMetaOrderList = async (organisationId) =>
  ordersApi().then((api) => api.getBasicMetaOrderList(organisationId).then(({ data }) => data));

export const getPartsOrgAddressList = async (organisationId) =>
  organisationsAddressesAPi().then((api) =>
    api.getAddressList(organisationId).then((response) => response)
  );

export const createPartsOrgAddress = async (organisationId, address) =>
  organisationsAddressesAPi().then((api) =>
    api.createAddress(organisationId, address).then(({ data }) => data)
  );

export const updatePartsOrgAddress = async (organisationId, address) =>
  organisationsAddressesAPi().then((api) =>
    api.updateAddress(organisationId, address?.addressId, address).then(({ data }) => data)
  );

export const deletePartsOrgAddress = async (organisationId, id) =>
  organisationsAddressesAPi().then((api) =>
    api.deleteAddress(organisationId, id).then(({ data }) => data)
  );
export { basePath };
