export const DRAWER_WIDTH = 300;
export const APP_BAR_HEIGHT = 56;
export const FOOTER_HEIGHT = 40;
export const DETAILED_DRAWER_WIDTH = 992;
export const DETAILED_DRAWER_WIDTH_MARKETPLACE = 1256;
export const BUY_REQUEST_DRAWER_WIDTH = 640;
export const MAIN_TABLE_MIN_WIDTH = 400;
export const TABLE_FILTER_WIDTH = 400;
export const NRAIL_PAGE_NAME_PREFIX = 'NRail Parts:';

export const FILTER_KEYS = [
  'assembly',
  'vehicleModelTypeId',
  'organisationId',
  'manufacturerId',
  'activeReservationCount',
  'minAmountAvailable',
  'amountAvailable',
  'state',
  'condition',
  'allowDirectBuy',
  'consumableCategoryId',
  'categoryId',
  'federalState',
  'contractType',
  'city',
  'sparePartsCategoryId',
  'workshopEquipmentCategoryId',
];

export const LISTING_STATE_MAPS = {
  not_submitted: 'warning',
  published: 'success',
  submitted: 'info',
  publication_rejected: 'error',
  unpublished: 'warning',
};
export const LISTING_IS_SUBMITTABLE_MAPS = {
  false: 'error',
  true: 'success',
};

export const getDevSettings = (prefix, removePrefix = true) => {
  const keysFound = Object.keys(localStorage).filter((item) =>
    item.toLowerCase().startsWith(prefix)
  );
  const prefixToRemoveRegex = new RegExp(prefix, 'ig');
  const newObjKeys = removePrefix
    ? keysFound.map((item) => item.replace(prefixToRemoveRegex, ''))
    : [...keysFound];

  const devItem = {};
  newObjKeys.forEach((newKey, index) => {
    devItem[newKey] = JSON.parse(localStorage[keysFound[index]]);
  });

  return devItem;
};

const DEV_DOMAINS = ['dev.parts-nrail.fuerstvonmartin.de', 'localhost:3000'];
export const DEV_SETTINGS = {
  show: getDevSettings('show_'),
};

export const LISTING_GALLERY_FALLBACK = [
  {
    original: '/assets/images/listing-image-fallback.jpg',
    default: '/assets/images/listing-image-fallback.jpg',
    thumbnail: '/assets/images/listing-image-fallback.jpg',
  },
];
export const IS_DEV_SYSTEM = DEV_DOMAINS.includes(
  window.location.origin.replace(/https?:\/\//i, '')
);
