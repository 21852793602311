const getNrlTypography = (theme, ZOOM_FACTOR) => ({
  // In Chinese and Japanese the characters are usually larger,
  // so a smaller fontsize may be appropriate.

  fontSize: Math.floor(14 * ZOOM_FACTOR),
  htmlFontSize: 16,

  nrlFormSectionLabel: {
    // variant
    fontWeight: '500',
  },

  h4: {
    fontSize: 24,
  },
});

export default getNrlTypography;
